<template>
    <div class="page">
        <div class="page-body">
            <div class="box">
                <div class="headline">
                    <div class="title">{{ $t('cms.categories.title') }}</div>
                    <div class="actions">
                        <el-tooltip :content="$t('cms.categories.create')" placement="left">
                            <el-button icon="el-icon-plus" type="primary" plain size="mini" @click="goRoute('categories/create')" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="body list" v-loading="loading">
                    <empty v-if="!elementSize(items(storePath(path))) && !loading" />
                    <template v-if="items(storePath(path))">
                        <div class="li cursor-pointer" v-for="(item, index) in items(storePath(path))" :key="index">
                            <div class="text" @click="goRoute('categories/' + item.id)">
                                <div class="title">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div class="actions inline">
                                <el-tooltip :content="$t('btn_delete')" placement="left">
                                    <el-button icon="el-icon-delete" type="danger" plain size="large" @click="destroy(item.id)" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="footer datatable-result" v-if="pagination(storePath(path))" v-html="$t('pagination_result', {total : pagination(storePath(path)).total})"></div>
            </div>
            <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS, DESTROY_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'CrudIndex',
    data() {
        return {
            path: 'cms/categories',
            loading: false,
            filter: {
                alias : null
            },
            page: 1
        }
    },
    computed: mapGetters(['pagination', 'items', 'ui']),
    mounted() {
        this.filter.alias = this.$route.meta.alias;
        //this.path = this.path + '/' + this.$route.meta.alias + '/items';
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },
        search() {
            this.page = 1;
            this.index();
        },
        index() {
            this.loading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;
                            this.index();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },
    }
}
</script>
