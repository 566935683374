<template>
    <draggable class="nested-items" tag="ul" :list="items" :group="{ name: 'g2' }">
        <template v-for="item in items">
            <li :key="item.id" class="nested-item" v-if="item">
                <div>
                    <div class="content">
                        <div class="text">
                            <div class="media mr-2">
                                <i class="mdi mdi-menu" />
                            </div>
                            <div>
                                <div class="title" v-if="item.text">
                                    {{ item.text.title }}
                                </div>
                                <div class="link" v-if="item.text">
                                    <a :href="publicSiteLink(item.text.url)" target="_blank">
                                        {{  item.text.url }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            <el-button plain icon="el-icon-plus" type="primary" @click="handleRowActionCommand({type : 'create', id : item.id})" />
                            <el-button plain icon="el-icon-edit" type="success" @click="handleRowActionCommand({type : 'edit', id : item.id})" />
                            <el-button plain icon="el-icon-delete" type="danger" @click="handleRowActionCommand({type : 'destroy', id : item.id})" />
                        </div>
                    </div>
                </div>
                <menu-link-nested :items="item.nested" :handleRowActionCommand="handleRowActionCommand" />
            </li>
        </template>
    </draggable>
</template>
<script>
import draggable from "vuedraggable";

export default {
    name: 'menu-link-nested',
    components: {
        draggable
    },
    props: {
        items: {
            required: true,
            type: Array
        },
        handleRowActionCommand : null,
    }
};
</script>
