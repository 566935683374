<template>
    <div>
        <div v-if="!isFile">
            <div class="el-upload-list el-upload-list--picture-card" v-if="!multiple">
                <div class="el-upload-list__item">
                    <img src="/img/dummy.png" alt="image" class="el-upload-list__item-thumbnail" v-if="!value">
                    <img :src="publicFilePath(value)" alt="image" class="el-upload-list__item-thumbnail" v-if="value">
                    <span class="el-upload-list__item-actions">
						<span class="el-upload-list__item-preview" @click="show">
							<el-button type="primary" icon="el-icon-edit" />
						</span>
						<span class="el-upload-list__item-delete text-color-danger" @click="destroy">
							<el-button type="danger" icon="el-icon-delete" />
						</span>
					</span>
                </div>
            </div>
        </div>

        <div v-if="isFile" class="form-item-capsule">
            <el-input v-model="value" :placeholder="placeholder ? placeholder : 'Dosya'" prefix-icon="el-icon-tickets" />
            <el-button type="primary" @click="show">
                <icon source="folder-multiple-outline"></icon>
            </el-button>
            <el-button type="danger" @click="destroy" v-if="value">
                <icon source="trash-can-outline"></icon>
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Fs',
    props: [
        'value',
        'multiple',
        'isFile',
        'placeholder',
    ],
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        show() {
            this.$store.commit('setVisibleDialogFileManager', true);
            this.$store.commit('setInputFileManager', this.add);
        },

        destroy() {
            this.$emit('input', '');
        },

        add(value) {
            this.$emit('input', 'storage/' + value);
        }
    }
};
</script>
