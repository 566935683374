<template>
    <div></div>
</template>

<script>
import JwtService from "@/services/jwt.service";

export default {
    name: 'LogoutAccount',
    mounted() {
        JwtService.destroyToken();
        this.goRoute('/auth/login')
    }
}
</script>
