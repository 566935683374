export const GET_UI = 'ui';
export const GET_UI_CONTACTS = 'getUiContacts';

// Auth
export const STORE_LOGIN = 'storeLogin';

// Account
export const GET_ACCOUNT = 'getAccount';
export const UPDATE_ACCOUNT = 'updateAccount';
export const UPDATE_ACCOUNT_PASSWORD = 'updateAccountPassword';
export const GET_ACCOUNT_ADDRESSES = 'getAccountAddresses';
export const STORE_ACCOUNT_ADDRESSES = 'storeAccountAddresses';
export const UPDATE_ACCOUNT_ADDRESSES = 'updateAccountAddresses';
export const DESTROY_ACCOUNT_ADDRESSES = 'destroyAccountAddresses';

// Crud
export const GET_CRUD_ITEMS = 'getCrudItems';
export const GET_CRUD_ITEM = 'getCrudItem';
export const STORE_CRUD_ITEM = 'storeCrudItem';
export const UPDATE_CRUD_ITEM = 'updateCrudItem';
export const DESTROY_CRUD_ITEM = 'destroyCrudItem';
