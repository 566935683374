<template>
    <div class="body">
        <div class="box-inline-headline first">Firma Bilgileri</div>

        <el-form-item :label="$t('inputs.company_real_name')">
            <el-input v-model="form.company_real_name" :placeholder="$t('inputs.company_real_name')" />
        </el-form-item>

        <el-form-item :label="$t('inputs.company_owner')">
            <el-input v-model="form.company_owner" :placeholder="$t('inputs.company_owner')" />
        </el-form-item>

        <el-form-item :label="$t('inputs.company_website')">
            <el-input v-model="form.company_website" :placeholder="$t('inputs.company_website')" />
        </el-form-item>

        <el-form-item :label="$t('inputs.company_email')">
            <el-input v-model="form.company_email" :placeholder="$t('inputs.company_email')" />
        </el-form-item>

        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item :label="$t('inputs.company_gsm')">
                    <el-input v-model="form.company_gsm" :placeholder="$t('inputs.company_gsm')" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item :label="$t('inputs.company_phone')">
                    <el-input v-model="form.company_phone" :placeholder="$t('inputs.company_phone')" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item :label="$t('inputs.company_whatsapp')">
                    <el-input v-model="form.company_whatsapp" :placeholder="$t('inputs.company_whatsapp')" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item :label="$t('inputs.company_whatsapp_link')">
                    <el-input v-model="form.company_whatsapp_link" :placeholder="$t('inputs.company_whatsapp_link')" />
                </el-form-item>
            </el-col>
        </el-row>

        <zones :form="form" :is_country="true" :is_city="true" :is_town="false" valueType="string" />

        <el-form-item :label="$t('inputs.company_address')">
            <el-input v-model="form.company_address" :placeholder="$t('inputs.company_address')" />
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'CompanySetting',
    props : ['form'],
}
</script>
