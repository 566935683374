<template>
    <div class="menu">
        <el-tooltip :content="$t('menu.dashboard')" placement="right">
            <router-link to="/dashboard" class="menu-item">
                <icon source="gauge" />
                <span>{{ $t('menu.dashboard') }}</span>
            </router-link>
        </el-tooltip>

        <el-popover placement="right" width="270" trigger="hover" popper-class="dropdown-menu dark account right" append-to-body>
            <div class="menu-item cursor-pointer" slot="reference">
                <icon source="file-document-edit-outline" />
                <span>{{ $t('menu.pages') }}</span>
            </div>
            <div class="body">
                <div class="head is-menu">
                    <div class="media"><icon source="file-document-edit-outline" /></div>
                    <div class="text"><div class="title">{{ $t('menu.pages') }}</div></div>
                </div>
                <router-link to="/cms/pages/categories" class="link-item">
                    <icon source="clipboard-list-outline" class="media" />
                    {{ $t('menu.categories') }}
                </router-link>
                <router-link to="/cms/pages/contents" class="link-item">
                    <icon source="file-document-outline" class="media" />
                    {{ $t('menu.contents') }}
                </router-link>
            </div>
        </el-popover>

        <el-popover placement="right" width="270" trigger="hover" popper-class="dropdown-menu dark account right" append-to-body>
            <div class="menu-item cursor-pointer" slot="reference">
                <icon source="post-outline" />
                <span>{{ $t('menu.posts') }}</span>
            </div>
            <div class="body">
                <div class="head is-menu">
                    <div class="media"><icon source="post-outline" /></div>
                    <div class="text"><div class="title">{{ $t('menu.posts') }}</div></div>
                </div>
                <router-link to="/cms/posts/categories" class="link-item">
                    <icon source="clipboard-list-outline" class="media" />
                    {{ $t('menu.categories') }}
                </router-link>
                <router-link to="/cms/posts/contents" class="link-item">
                    <icon source="file-document-outline" class="media" />
                    {{ $t('menu.contents') }}
                </router-link>
            </div>
        </el-popover>

        <el-popover placement="right" width="270" trigger="hover" popper-class="dropdown-menu dark account right" append-to-body>
            <div class="menu-item cursor-pointer" slot="reference">
                <icon source="tag-multiple-outline" />
                <span>{{ $t('menu.catalog') }}</span>
            </div>
            <div class="body">
                <div class="head is-menu">
                    <div class="media">
                        <icon source="tag-multiple-outline" />
                    </div>
                    <div class="text">
                        <div class="title">{{ $t('menu.catalog') }}</div>
                    </div>
                </div>
                <router-link to="/catalog/categories" class="link-item">
                    <icon source="clipboard-list-outline" class="media" />
                    {{ $t('menu.categories') }}
                </router-link>
                <router-link to="/catalog/products" class="link-item">
                    <icon source="package-up" class="media" />
                    {{ $t('menu.products') }}
                </router-link>
                <router-link to="/catalog/brands" class="link-item" style="display: none">
                    <icon source="watermark" class="media" />
                    {{ $t('menu.brands') }}
                </router-link>
                <router-link to="/catalog/features" class="link-item" style="display: none">
                    <icon source="list-status" class="media" />
                    {{ $t('menu.features') }}
                </router-link>
            </div>
        </el-popover>

        <el-popover placement="right" width="270" trigger="hover" popper-class="dropdown-menu dark account right" append-to-body>
            <div class="menu-item cursor-pointer" slot="reference">
                <icon source="calendar-star" />
                <span>{{ $t('menu.events') }}</span>
            </div>
            <div class="body">
                <div class="head is-menu">
                    <div class="media"><icon source="calendar-star"/></div>
                    <div class="text"><div class="title">{{ $t('menu.events') }}</div></div>
                </div>
                <router-link to="/cms/events/categories" class="link-item">
                    <icon source="clipboard-list-outline" class="media" />
                    {{ $t('menu.categories') }}
                </router-link>
                <router-link to="/cms/events/contents" class="link-item">
                    <icon source="file-document-outline" class="media" />
                    {{ $t('menu.contents') }}
                </router-link>
            </div>
        </el-popover>

        <el-popover placement="right" width="270" trigger="hover" popper-class="dropdown-menu dark account right" append-to-body>
            <div class="menu-item cursor-pointer" slot="reference">
                <icon source="briefcase-account-outline" />
                <span>{{ $t('menu.references') }}</span>
            </div>
            <div class="body">
                <div class="head is-menu">
                    <div class="media"><icon source="briefcase-account-outline"/></div>
                    <div class="text"><div class="title">{{ $t('menu.references') }}</div></div>
                </div>
                <router-link to="/cms/references/categories" class="link-item">
                    <icon source="clipboard-list-outline" class="media" />
                    {{ $t('menu.categories') }}
                </router-link>
                <router-link to="/cms/references/contents" class="link-item">
                    <icon source="file-document-outline" class="media" />
                    {{ $t('menu.contents') }}
                </router-link>
            </div>
        </el-popover>

        <el-popover placement="right" width="270" trigger="hover" popper-class="dropdown-menu dark account right" append-to-body>
            <div class="menu-item cursor-pointer" slot="reference">
                <icon source="frequently-asked-questions" />
                <span>{{ $t('menu.faqs') }}</span>
            </div>
            <div class="body">
                <div class="head is-menu">
                    <div class="media"><icon source="frequently-asked-questions" /></div>
                    <div class="text"><div class="title">{{ $t('menu.faqs') }}</div></div>
                </div>
                <router-link to="/cms/faqs/categories" class="link-item">
                    <icon source="clipboard-list-outline" class="media" />
                    {{ $t('menu.categories') }}
                </router-link>
                <router-link to="/cms/faqs/contents" class="link-item">
                    <icon source="file-document-outline" class="media" />
                    {{ $t('menu.contents') }}
                </router-link>
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    name: 'MenuSidebar'
}
</script>
