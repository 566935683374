import lodash from "lodash";
import ApiService from '../services/api.service';
import FormResponseService from "@/services/form_response.service";

import {
    GET_CRUD_ITEMS,
    GET_CRUD_ITEM,
    STORE_CRUD_ITEM,
    UPDATE_CRUD_ITEM,
    DESTROY_CRUD_ITEM,
} from "./actions.type";

import {
    SET_ERROR,
    SET_CRUD_PAGINATION,
    SET_CRUD_ITEMS,
    SET_CRUD_ITEM,
    SET_PUSH_CRUD_ITEM,
    SET_UPDATE_CRUD_ITEM,
    SET_DESTROY_CRUD_ITEM,
} from "./mutations.type";

const generateProp = (payload) => {
    let paths = payload.split('/')

    return paths.join('_');
}

const state = {
    pagination: {
        //
    },
    items: {
        //
    },
    item: {
        //
    },
};

const getters = {
    pagination: (state) => (path) => {
        return state.pagination[path];
    },
    items: (state) => (path) => {
        return state.items[path];
    },
    item: (state) => (path) => {
        return state.item[path];
    }
};

const mutations = {
    [SET_CRUD_PAGINATION](state, payload) {
        state.pagination = lodash.assign(JSON.parse(JSON.stringify(state.pagination)), {
            [generateProp(payload.path)]: payload.pagination
        });
    },

    [SET_CRUD_ITEMS](state, payload) {
        state.items = lodash.assign(JSON.parse(JSON.stringify(state.items)), {
            [generateProp(payload.path)]: payload.data
        });
    },

    [SET_CRUD_ITEM](state, payload) {
        state.item = lodash.assign(JSON.parse(JSON.stringify(state.item)), {
            [generateProp(payload.path)]: payload.data
        });
    },

    [SET_PUSH_CRUD_ITEM](state, payload) {
        if (!state.items[generateProp(payload.path)]) {
            state.items[generateProp(payload.path)] = [];
        }

        state.items[generateProp(payload.path)].push(payload.data);
    },

    [SET_UPDATE_CRUD_ITEM](state, payload) {
        lodash.forEach(state.items[generateProp(payload.path)], (item, key) => {
            if (item.id === payload.data.id) {
                state.items[generateProp(payload.path)][key] = payload.data;
            }
        })
    },

    [SET_DESTROY_CRUD_ITEM](state, payload) {
        lodash.forEach(state.items[generateProp(payload.path)], (item, key) => {
            if (item.id === payload.id) {
                state.items[generateProp(payload.path)][key].id = null;
            }
        });
    },
};

const actions = {
    [GET_CRUD_ITEMS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(params.path, {params: params})
                .then(({data}) => {
                    context.commit(SET_CRUD_ITEMS, {data : data.data, path : params.path});
                    context.commit(SET_CRUD_PAGINATION, {pagination : data.pagination, path : params.path});
                    resolve(data.data);
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        }).catch((reject) => {
            console.log(reject)
        });
    },

    [GET_CRUD_ITEM](context, params) {
        return new Promise((resolve, reject) => {

            let path = params.path;

            if (params.id) {
                path += '/' + params.id;
            }

            ApiService.get(path, {params: params})
                .then(({data}) => {
                    context.commit(SET_CRUD_ITEM, {data : data.data, path : params.path});
                    resolve(data.data);
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        }).catch((reject) => {
            console.log(reject)
        });
    },

    [STORE_CRUD_ITEM](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.store(params.path, params)
                .then(({data}) => {
                    if (!params.disable_push) {
                        context.commit(SET_PUSH_CRUD_ITEM, {data : data.data, path : params.path});
                    }

                    console.log(data)

                    resolve(data.data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        }).catch((reject) => {
            console.log(reject)
        });
    },

    [UPDATE_CRUD_ITEM](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.update(params.path + '/' + params.id, params)
                .then(({data}) => {
                    context.commit(SET_UPDATE_CRUD_ITEM, {data : data.data, path : params.path});
                    resolve(data.data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        }).catch((reject) => {
            console.log(reject)
        });
    },

    [DESTROY_CRUD_ITEM](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.destroy(params.path + '/' + params.id, params)
                .then(({data}) => {
                    context.dispatch(GET_CRUD_ITEMS, params)
                    resolve(data.data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        }).catch((reject) => {
            console.log(reject)
        });
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
