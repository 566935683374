<template>
    <div class="error-page error-page-404">
        <div class="wrapper">
            <div class="container">
                <div class="content">
                    <div class="code">503</div>
                    <div class="message">{{ $t('error.maintenance_title') }}</div>
                </div>
                <div class="media">
                    <img src="/img/errors/maintenance.svg" alt="error">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Maintenance',
    mounted() {
        //
    }
}
</script>
