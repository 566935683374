<template>
    <div v-loading="pageLoading" class="margin-top-20">
        <div class="box">
            <div class="headline large">
                <div class="title">Linkler</div>
                <div class="actions">
                    <el-tooltip content="Siralama Islemini Kaydet" placement="bottom">
                        <el-button type="success" plain size="small" icon="el-icon-check" @click="updateSort" />
                    </el-tooltip>
                    <el-tooltip content="Ekle" placement="bottom">
                        <el-button type="primary" plain size="small" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </div>
            <div class="body" v-if="!pageLoading">
                <menu-link-nested :items="responseItems" :handleRowActionCommand="handleRowActionCommand" />
            </div>
        </div>

        <el-dialog title="Link" :visible.sync="dialogVisible" v-loading="dialogLoading">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Görünüm Türü">
                    <select-box :options="layouts" :container="form" model="view_type" placeholder="Görünüm Türü"/>
                </el-form-item>

                <el-form-item label="Başlık">
                    <language-input type="string" :items="form.texts" model="title" />
                </el-form-item>

                <el-form-item label="Url">
                    <language-input type="string" :items="form.texts" model="url" />
                </el-form-item>

                <el-form-item label="Siralama">
                    <el-input v-model="form.sort" placeholder="Siralama" type="number" />
                </el-form-item>

                <el-collapse >
                    <el-collapse-item title="Link Ozellikleri" name="1">
                        <el-form-item label="Html Class">
                            <el-input v-model="form.html_class" placeholder="Html Class" />
                        </el-form-item>

                        <el-form-item label="Ikon">
                            <el-input v-model="form.icon" placeholder="Ikon" />
                        </el-form-item>

                        <el-form-item label="Açıklama">
                            <el-input type="textarea" rows="4" placeholder="Açıklama" v-model="form.description" />
                        </el-form-item>

                        <el-form-item label="Öncesi">
                            <el-input type="textarea" rows="4" placeholder="Öncesi" v-model="form.before" />
                        </el-form-item>

                        <el-form-item label="Sonrası">
                            <el-input type="textarea" rows="4" placeholder="Sonrası" v-model="form.after" />
                        </el-form-item>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid" type="success" :loading="loading" :disabled="loading" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid" type="success" :loading="loading" :disabled="loading" @click="update" v-if="form.id">
                    Güncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS, GET_CRUD_ITEM, STORE_CRUD_ITEM, UPDATE_CRUD_ITEM, DESTROY_CRUD_ITEM} from "@/store/actions.type";
import MenuLinkNested from "./Nested";

export default {
    name: 'MenuLink',
    components: {MenuLinkNested},
    props : ['id'],
    data() {
        return {
            path: 'design/menus',
            loading: false,
            filter: {
                menu_id : null
            },
            page: 1,
            pageLoading: false,
            dialogLoading: false,
            dialogVisible: false,
            form: {
                texts : [],
                meta : {
                    //
                }
            },

            layouts: [
                {
                    id: 'basic',
                    title: 'Varsayılan'
                },
                {
                    id: 'onlyLinks',
                    title: 'Sadece Linkleri Göster'
                },
                {
                    id: 'mega',
                    title: 'Mega Menü'
                }
            ],

            responseItems : [],
            sortItems : [],
        }
    },
    computed: mapGetters(['items', 'item', 'ui']),
    mounted() {
        this.path = this.path + '/' + this.id + '/links';
        this.filter.menu_id = this.id

        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then((response) => {
                    this.pageLoading = false;
                    this.responseItems = response;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create(parentId) {
            this.form = {
                parent_id : parseInt(parentId) ? parentId : 0,
                texts : [],
                meta : {
                    //
                }
            };

            this.initLanguageWithTextForm(this.form.texts);

            this.dialogVisible = true;
        },

        store() {
            this.loading = true;
            this.form.path = this.path
            this.form.menu_id = this.id

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.loading = false;
                    this.dialogVisible = false;

                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        show(id) {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                    this.dialogLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        update() {
            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;

                            this.index();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },

        updateSort() {
            this.pageLoading = true;
            this.form.path = this.path;

            this.sortItems = [];

            this.generateRequestSortItems(this.responseItems);

            this.$store
                .dispatch(STORE_CRUD_ITEM, {
                    items : this.sortItems,
                    view : 'sort',
                    path : this.path,
                })
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                });
        },

        generateRequestSortItems(items, parentId) {

            if (!parentId) {
                parentId = 0;
            }

            this.lodash.forEach(items, (item, key) => {
                this.sortItems.push({
                    id: item.id,
                    parent_id: parentId,
                    sort : key
                });

                if (this.lodash.size(item.nested)) {
                    this.generateRequestSortItems(item.nested, item.id)
                }
            });
        },

        handleRowActionCommand(command) {
            if (command.type === 'destroy') {
                return this.destroy(command.id);
            }

            if (command.type === 'create') {
                return this.create(command.id);
            }

            this.edit(command.id);
        },
    }
}
</script>
