import ApiService from '../services/api.service';
import JwtService from "../services/jwt.service";

import {
    STORE_LOGIN,
} from "./actions.type";

import {
    SET_ERROR,
    SET_AUTH_TOKEN,
    DESTROY_AUTH_TOKEN,
} from "./mutations.type";

const state = {
    //
};

const getters = {
    //
};

const mutations = {
    [SET_AUTH_TOKEN](state, payload) {
        JwtService.storeToken(payload.access_token);
        JwtService.getToken();

        return window.location = '/dashboard';
    },
    [DESTROY_AUTH_TOKEN]() {
        JwtService.destroyToken();
    },
};

const actions = {
    [STORE_LOGIN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.store('auth/login', payload)
                .then(({data}) => {
                    context.commit(SET_AUTH_TOKEN, data.data);
                    resolve(data);
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
