<template>
    <div class="body">
        <div class="box-inline-headline first">Dil Ayarları</div>
        <el-form-item label="Varsayılan Dil">
            <select-box :container="form" model="site_language_id" :options="ui.languages" :clearable="false" valuetype="string" :changeFull="true" :change="handleChangeLanguage" />
        </el-form-item>

        <div class="box-inline-headline">Seo Ayarları</div>

        <el-form-item label="Meta Title">
           <language-input :items="form.seo" model="meta_title" type="string" />
       </el-form-item>

        <el-form-item label="Meta Description">
            <language-input :items="form.seo" model="meta_description"  type="text" />
        </el-form-item>

        <el-form-item label="Meta Keywords">
            <language-input :items="form.seo" model="meta_keywords" type="text" />
        </el-form-item>

        <el-form-item label="Meta Owner" class="margin-bottom-0">
            <el-input v-model="form.meta_author" placeholder="Meta Owner" />
        </el-form-item>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_UI_LANGUAGE} from "@/store/mutations.type";

export default {
    name: 'SiteSetting',
    props : ['form'],
    computed: mapGetters(['ui']),
    methods : {
        handleChangeLanguage(data) {
            this.$store.commit(SET_UI_LANGUAGE, data);
        }
    }
}
</script>
