<template>
    <div v-loading="pageLoading">
        <div class="box">
            <div class="headline">
                <div class="title">Dosyalar</div>
                <div class="actions">
                    <el-tooltip content="Dsoya Ekle" placement="left">
                        <el-button icon="el-icon-plus" type="primary" plain size="mini" @click="create" />
                    </el-tooltip>
                </div>
            </div>
            <div class="body list" v-loading="loading">
                <empty v-if="!elementSize(items(storePath(path))) && !loading" />
                <template v-if="items(storePath(path))">
                    <div class="li cursor-pointer" v-for="(file, index) in items(storePath(path))" :key="index">
                        <div class="content" @click="show(file.id)" v-if="file">
                            <div class="media is-image">
                                <img :src="publicFilePath(file.path)" alt="file">
                            </div>
                            <div class="text">
                                <div class="title">
                                    {{ file.title }}
                                </div>
                            </div>
                        </div>
                        <div class="actions inline">
                            <el-tooltip :content="$t('btn_delete')" placement="left">
                                <el-button icon="el-icon-delete" type="danger" plain size="large" @click="destroy(file.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </div>
            <div class="footer datatable-result" v-if="pagination(storePath(path))" v-html="$t('pagination_result', {total : pagination(storePath(path)).total})"></div>
        </div>
        <page-pagination :page="page" :path="path" :pagination="pagination" :change="handleChangePage" />

        <el-dialog title="Dosya Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="60%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Başlık">
                    <language-input type="string" :items="form.file.texts" model="title" />
                </el-form-item>

                <el-form-item label="Açıklama">
                    <language-input type="text" :items="form.file.texts" model="description" />
                </el-form-item>

                <el-form-item label="Siralama">
                    <el-input v-model="form.sort" placeholder="Siralama" type="number" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="Resim" class="margin-bottom-0">
                            <fs v-model="form.file.path" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid" type="success" size="large" :loading="loading" :disabled="loading" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid" type="success" size="large" :loading="loading" :disabled="loading" @click="update" v-if="form.id">
                    Güncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS, DESTROY_CRUD_ITEM, STORE_CRUD_ITEM, GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'Files',
    props : ['id'],
    data() {
        return {
            path: 'cms/contents',
            pageLoading: false,
            loading: false,
            submitted: false,
            filter: {
                content_id : null
            },
            page: 1,

            form : {
                content_id : null,
                file : {
                    texts : []
                }
            },

            dialogVisible : false,
        }
    },
    validations: {
        form: {
            //
        }
    },
    computed: mapGetters(['ui', 'pagination', 'items', 'item']),
    mounted() {
        this.path = this.path + '/' + this.id + '/files';
        this.filter.content_id = this.id;
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        create() {
            this.dialogVisible = true;
            this.form = {
                content_id : this.id,
                file : {
                    texts : []
                }
            }

            this.initLanguageWithTextForm(this.form.file.texts);
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path
            this.form.content_id = this.id;
            this.form.disable_push = true;

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        show(id) {
            this.dialogVisible = true;
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));

                    if (!this.form.file) {
                        this.form.file = {
                            texts : []
                        }

                        this.initLanguageWithTextForm(this.form.file.texts);
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;
            this.form.disable_push = true;

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;

                    this.index();
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;
                            this.index();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },
    }
}
</script>
