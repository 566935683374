<template>
    <div class="page">
        <div class="page-body">
            <div class="box no-shadow">
                <div class="body">
                    <div class="box-inline-headline first">
                        {{ $t('account.password_title') }}
                    </div>
                    <el-form :model="form" label-position="top" ref="AppForm">
                        <el-form-item :label="$t('inputs.your_password')" :class="{ 'is-error': submitted && $v.form.old_password.$error }">
                            <el-input type="password" v-model="form.old_password" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_password')" class="large" show-password />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.your_new_password')" :class="{ 'is-error': submitted && $v.form.password.$error }">
                            <el-input type="password" v-model="form.password" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_new_password')" class="large" show-password />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.your_new_password_repeat')" :class="{'is-error': submitted && $v.form.password_confirmation.$error }">
                            <el-input type="password" v-model="form.password_confirmation" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_new_password_repeat')" class="large" show-password />
                        </el-form-item>

                        <el-button type="primary" size="large" class="margin-top-20 fluid" @click="store" :loading="loading" :disabled="loading">
                            {{ $t('btn_change') }}
                        </el-button>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {required, minLength, maxLength, sameAs} from "vuelidate/lib/validators";
import {UPDATE_ACCOUNT_PASSWORD} from "@/store/actions.type";

export default {
    name: 'PasswordAccount',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            }
        }
    },
    validations: {
        form: {
            old_password: {required, minLength: minLength(6), maxLength: maxLength(32)},
            password: {required, minLength: minLength(6), maxLength: maxLength(32)},
            password_confirmation: {required, sameAsPassword: sameAs('password')},
        }
    },
    mounted() {
        //
    },
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(UPDATE_ACCOUNT_PASSWORD, this.form)
                .then(() => {
                    this.loading = false;
                    this.submitted = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
