<template>
    <div v-loading="pageLoading">
        <form-box :title="$t('tools.form_messages.create')" back_link="/tools/form-messages" :store="store" :loading="loading">
            <el-form ref="form" :model="form">

                <el-form-item :label="$t('inputs.type')" :class="{ 'is-error': submitted && $v.form.alias.$error }">
                    <select-box :options="ui.theme.file_data.form_message_aliases" :container="form" model="alias" :placeholder="$t('inputs.type')"/>
                </el-form-item>

                <el-form-item label="Ad Soyad">
                    <el-input v-model="form.name" placeholder="Ad Soyad" />
                </el-form-item>

                <el-form-item label="Rol / Meslek">
                    <el-input v-model="form.role" placeholder="Rol / Meslek" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Telefon">
                            <el-input v-model="form.phone" placeholder="Telefon" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="E-Posta">
                            <el-input v-model="form.email" placeholder="E-Posta" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Mesaj">
                    <el-input v-model="form.message" placeholder="Mesaj" rows="4" type="textarea" />
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_share" active-text="Web sitesinde yayınla" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="4">
                        <el-form-item label="Fotoğraf" class="margin-bottom-0">
                            <fs v-model="form.file.path" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </form-box>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {STORE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'CrudShow',
    data() {
        return {
            path: 'tools/form-messages',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                file : {
                    //
                },
                inputs : []
            }
        }
    },
    validations: {
        form: {
            alias: {required},
            inputs: {},
        }
    },
    computed: mapGetters(['item', 'ui']),
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;
                    this.goRoute('/tools/form-messages/' + response.id)
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
