<template>
    <div>
        <div class="box-inline-headline first">Görseller</div>
        <el-row :gutter="24">
            <el-col :span="4">
                <el-form-item label="Favicon">
                    <fs v-model="form.meta.favicon" />
                </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-form-item label="Logo">
                    <fs v-model="form.meta.logo" />
                </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-form-item label="Logo Koyu">
                    <fs v-model="form.meta.logo_dark" />
                </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-form-item label="Logo Acik">
                    <fs v-model="form.meta.logo_light" />
                </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-form-item label="Footer Logo">
                    <fs v-model="form.meta.logo_footer" />
                </el-form-item>
            </el-col>
        </el-row>
        <div class="box-inline-headline">Menüler</div>
        <el-row :gutter="24">
            <el-col :span="8" v-for="menu in form.file_data.menus" :key="menu.value">
                <el-form-item :label="menu.label">
                    <select-box valuetype="string" remote="design/menus" :container="form.meta" :model="menu.value"/>
                </el-form-item>
            </el-col>
        </el-row>

        <div class="box-inline-headline">Html Meta Tags</div>
        <el-form-item label="Html Meta">
            <vue-prism-editor v-model="form.html_meta" language="markup" :lineNumbers="true" />
        </el-form-item>

        <div class="box-inline-headline">Styles</div>
        <el-form-item label="Style Links">
            <el-input v-model="form.style_links" placeholder="Style Links" />
        </el-form-item>

        <el-form-item label="Style Code">
            <vue-prism-editor v-model="form.style_code" language="css" :lineNumbers="true" />
        </el-form-item>

        <div class="box-inline-headline">Javascript</div>
        <el-form-item label="Js Scripts">
            <el-input v-model="form.js_scripts" placeholder="Js Scripts" />
        </el-form-item>

        <el-form-item label="Js Code">
            <vue-prism-editor v-model="form.js_code" language="js" :lineNumbers="true" />
        </el-form-item>
    </div>
</template>

<script>
import 'prismjs'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-markup-templating';
import VuePrismEditor from 'vue-prism-editor';

export default {
    name: 'Default',
    components: {
        VuePrismEditor,
    },
    props : ['form'],
    methods: {
        highlighter(code) {
            return Prism.highlight(code, Prism.languages.js, "js");
        }
    },
}
</script>
