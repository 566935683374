<template>
    <div class="item search">
        <el-form>
            <el-form-item>
                <el-select v-model="value" filterable remote reserve-keyword :placeholder="$t('page_header_search')" :remote-method="remoteMethod" popper-class="global-search" :loading="loading" @change="handleChange">
                    <el-option v-for="item in results" :key="item.link" :label="item.title" :value="item.link">
                        <span class="global-search-item">
                            <span class="global-search-item-media">
                               <icon :source="iconFinder(item.alias)" />
                            </span>
                            <span class="global-search-item-content">
                                <span class="global-search-item-title">{{ item.title }}</span>
                            </span>
                        </span>
                    </el-option>
                    <template slot="prefix">
                        <i class="el-input__icon"
                           :class="{'el-icon-loading' : loading, 'el-icon-search' : !loading}"></i>
                    </template>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    name: 'SearchHeader',
    data() {
        return {
            path: 'search/contents',
            loading: false,
            value: null,
            results: [],
        }
    },
    methods: {
        remoteMethod(query) {
            if (query === '') {
                return this.results = [];
            }

            if (this.elementSize(query) <= 3) {
                return this.results = [];
            }

            this.loading = true;

            ApiService.get(this.path, {params: {q: query}})
                .then(({data}) => {
                    this.results = data.data;
                    this.loading = false;
                })
        },
        handleChange(event) {
            this.goRoute(event);
        },
        iconFinder(type) {

            if (type === 'pages') {
                return 'file-document-edit-outline';
            }

            if (type === 'posts') {
                return 'post-outline';
            }

            if (type === 'products') {
                return 'tag-multiple-outline';
            }

            if (type === 'events') {
                return 'calendar-star';
            }

            if (type === 'references') {
                return 'briefcase-account-outline';
            }

            if (type === 'faqs') {
                return 'frequently-asked-questions';
            }
        }
    }
}
</script>

<style lang="scss">
.global-search {
    width : calc(100% - 180px) !important;
    left  : 90px !important;

    .el-select-dropdown__item {
        height      : auto !important;
        line-height : 24px !important;
        padding     : 10px 15px !important;
    }
}

.global-search-item {
    display     : flex;
    align-items : center;

    .global-search-item-media {
        flex-shrink : 0;
        width       : 40px;
        height      : 30px;
        display     : flex;
        align-items : center;
        font-size   : 24px;
    }

    .global-search-item-content {
        display : block;

        .global-search-item-title {
            display     : block;
            font-weight : 500;
            font-size   : 16px;

            .global-search-item-metas {
                display     : flex;
                align-items : center;

                .meta {
                    margin-right : 10px;
                    display      : block;
                    font-size    : 12px;

                    &:last-child {
                        margin-right : 0;
                    }
                }
            }
        }
    }
}
</style>
