<template>
    <div>
        <template v-for="(block, index) in form.file_data.blocks">
            <div :key="block.value">
                <div class="box-inline-headline" :class="{first : !index}">{{ block.label }}</div>
                <el-form-item class="is-editor">
                    <language-input type="editor" :items="form.meta[block.value]" :model="block.value" />
                </el-form-item>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Block',
    props : ['form'],
}
</script>
