<template>
    <div>
        <div class="box">
            <div class="body list large">
                <empty v-if="!elementSize(items(storePath(path))) && !loading" />
                <div class="li cursor-pointer" v-for="(item, index) in items(storePath(path))" :key="index" @click="goRoute('/account/companies/apps/' + item.id)">
                    <div class="media large is-image">
                        <img :src="item.image" :alt="item.title">
                    </div>
                    <div class="text">
                        <div class="title">{{ item.title }}</div>
                        <div class="sub-title limited">{{ item.short_description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'Index',
    data() {
        return {
            path: 'plugins',
            loading: false,
            filter: {
                //
            },
            page: 1,
            options : []
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(page) {
            this.page = page;
            this.index();
        },
        index() {
            this.loading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
