<template>
    <el-popover :placement="placement" :width="width" trigger="click" :popper-class="'dropdown-menu dark account ' + aligment" append-to-body v-if="account">
        <div class="item cursor-pointer" slot="reference">
            <avatar :name="account.name" :source="account.avatar" />
        </div>
        <div class="body">
            <div class="head">
                <div class="media">
                    <avatar :name="account.name" :source="account.avatar" />
                </div>
                <div class="text">
                    <div class="title">{{ account.name }}</div>
                </div>
            </div>

            <router-link to="/account/me" class="link-item">
                <icon source="account-outline" class="media" />
                {{ $t('account.menu.head_1') }}
            </router-link>

            <router-link to="/account/logout" class="link-item">
                <icon source="logout" class="media" />
                {{ $t('account.menu.logout') }}
            </router-link>
        </div>
    </el-popover>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_ACCOUNT} from "@/store/actions.type";

export default {
    name: 'Account',
    props : {
        placement: {
            type: String,
            default: 'right-end'
        },
        width: {
            type: Number,
            default: 270
        },
        aligment: {
            type: String,
            default: 'right'
        },
    },
    data() {
        return {
            //
        }
    },
    computed: mapGetters(['account', 'ui']),
    mounted() {
        this.$store.dispatch(GET_ACCOUNT);
    },
    methods: {
        //
    }
}
</script>
