<template>
    <div class="seo-capsule">
        <el-form-item label="Meta Title">
            <language-input type="string" :items="items" model="meta_title" />
        </el-form-item>

        <el-form-item label="Meta Description">
            <language-input type="text" :items="items" model="meta_description" />
        </el-form-item>

        <el-form-item label="Meta Keywords">
            <language-input type="text" :items="items" model="meta_keywords" />
        </el-form-item>
    </div>
</template>

<script>

export default {
    name: 'Seo',
    props: {
        items: {
            default : []
        },
    },
    data() {
        return {

        }
    }
}
</script>
