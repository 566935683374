import lodash from 'lodash';
import ApiService from '../services/api.service';
import JwtService from '../services/jwt.service';
import FormResponseService from '../services/form_response.service';

import {
    GET_ACCOUNT,
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_PASSWORD,
    GET_ACCOUNT_ADDRESSES,
    STORE_ACCOUNT_ADDRESSES,
    UPDATE_ACCOUNT_ADDRESSES,
    DESTROY_ACCOUNT_ADDRESSES,
} from "./actions.type";

import {
    SET_ERROR,
    SET_PRELOADING,
    SET_ACCOUNT,
    SET_ADDRESSES,
    SET_PUSH_ADDRESS,
    SET_UPDATE_ADDRESS,
    SET_DESTROY_ADDRESS,
    SET_CHANGE_MAIN_ADDRESS,
} from "./mutations.type";

const state = {
    account: null,
    addresses: null,
};

const getters = {
    account(state) {
        return state.account;
    },
    addresses(state) {
        return state.addresses;
    }
};

const mutations = {
    [SET_ACCOUNT](state, payload) {
        state.account = payload;
    },

    [SET_ADDRESSES](state, payload) {
        state.addresses = payload;
    },

    [SET_PUSH_ADDRESS](state, payload) {
        state.addresses.push(payload);
    },

    [SET_UPDATE_ADDRESS](state, payload) {
        lodash.forEach(state.addresses, (item, key) => {
            if (item.id === payload.id) {
                state.addresses[key] = payload;
            }
        })
    },

    [SET_DESTROY_ADDRESS](state, payload) {
        lodash.forEach(state.addresses, (item, key) => {
            if (item.id === payload.id) {
                state.addresses[key].id = null;
            }
        })
    },

    [SET_CHANGE_MAIN_ADDRESS](state, payload) {
        lodash.forEach(state.addresses, (item, key) => {
            state.addresses[key].is_main = false;

            if (item.id === payload) {
                state.addresses[key].is_main = true;
            }
        })
    }
};

const actions = {
    [GET_ACCOUNT](context, isRefresh = false) {
        if (context.state.account && !isRefresh) {
            return false;
        }

        if (!isRefresh) {
            context.commit(SET_PRELOADING, true);
        }

        return new Promise((resolve, reject) => {
            ApiService.get('account/me')
                .then(({data}) => {
                    context.commit(SET_ACCOUNT, data.data);
                    resolve(data);

                    setTimeout(() => {
                        context.commit(SET_PRELOADING, false);
                    }, 500)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                    JwtService.destroyToken();

                    setTimeout(() => {
                        window.location = '/auth/login'
                    }, 1000)
                });
        });
    },

    [UPDATE_ACCOUNT](context, params) {
        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'multipart/form-data'
            }

            ApiService.store('account/me', params, {headers: headers})
                .then(({data}) => {
                    context.commit(SET_ACCOUNT, data.data);
                    resolve(data);

                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    },

    [UPDATE_ACCOUNT_PASSWORD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.update('account/password', params)
                .then(({data}) => {
                    resolve(data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    },

    [GET_ACCOUNT_ADDRESSES](context) {
        return new Promise((resolve, reject) => {
            ApiService.get('account/addresses')
                .then(({data}) => {
                    context.commit(SET_ADDRESSES, data.data);
                    resolve(data.data);
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    },

    [STORE_ACCOUNT_ADDRESSES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.store('account/addresses', params)
                .then(({data}) => {
                    context.commit(SET_PUSH_ADDRESS, data.data);
                    resolve(data.data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    },

    [UPDATE_ACCOUNT_ADDRESSES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.update('account/addresses/' + params.id, params)
                .then(({data}) => {
                    context.commit(SET_UPDATE_ADDRESS, data.data);
                    resolve(data.data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    },

    [DESTROY_ACCOUNT_ADDRESSES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.destroy('account/addresses/' + params.id)
                .then(({data}) => {
                    context.commit(SET_DESTROY_ADDRESS, params);
                    resolve(data.data);
                    FormResponseService.success(data.message)
                })
                .catch(({data}) => {
                    context.commit(SET_ERROR, data);
                    reject(data);
                });
        });
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
