<template>
    <form-box :title="$t('tools.form_messages.title')" icon="android-messages" back_link="/tools/form-messages" :store="store" :loading="loading">
        <el-form ref="form" :model="form">

            <el-form-item :label="$t('inputs.type')" :class="{ 'is-error': submitted && $v.form.alias.$error }">
                <select-box :options="ui.theme.file_data.form_message_aliases" :container="form" model="alias" :placeholder="$t('inputs.type')"/>
            </el-form-item>


        </el-form>
    </form-box>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {STORE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'CrudCreate',
    data() {
        return {
            path: 'tools/form-messages',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
               //
            }
        }
    },
    validations: {
        form: {
            alias: {required},
            inputs: {},
        }
    },
    computed: mapGetters(['ui']),
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;

                    setTimeout(() => {
                        this.loading = false;
                        this.goRoute('/tools/form-messages/' + response.id)
                    }, 1000)
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
