<template>
    <div class="page">
        <div class="page-body">
            <div class="box">
                <div class="headline">
                    <div class="title">
                        <icon source="microsoft-excel" />
                        {{ $t('system.exports.title') }}
                    </div>
                </div>
                <div class="body" v-loading="pageLoading">
                    <el-form :label-position="'top'" ref="form" :model="form" v-if="!pageLoading">
                        <el-form-item :label="$t('inputs.schemas')">
                            <select-box :options="schemaOptions" :container="form" model="schema" icon="clipboard-text-play-outline" :change="handleChangeSchema" />
                        </el-form-item>
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item :label="$t('inputs.start_at')">
                                    <el-date-picker v-model="form.start_at" type="datetime" :placeholder="$t('inputs.start_at')" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="datePickerOptions" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('inputs.end_at')">
                                    <el-date-picker v-model="form.end_at" type="datetime" :placeholder="$t('inputs.end_at')" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="datePickerOptions" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <template v-if="schema">
                            <div class="box-inline-headline">{{ $t('system.exports.detail') }}</div>
                            <el-form-item>
                                <el-transfer :titles="[$t('inputs.source'), $t('system.exports.columns')]" v-model="form.columns" :data="schema.columns" :key="schema.key" />
                            </el-form-item>
                        </template>
                    </el-form>
                </div>
            </div>

            <div class="margin-top-40" v-if="schema">
                <el-button class="fluid" icon="el-icon-download" size="large" type="primary" @click="download" :disabled="loading" :loading="loading">
                    {{ $t('system.exports.download_btn') }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";
import ApiService from "@/services/api.service";

export default {
    name: 'ExportIndex',
    data() {
        return {
            path: 'system/exports',
            pageLoading: false,
            loading: false,
            form: {
                schema: null,
                columns: [],
                products: [],
                class_definition_ids: [],
                year_definition_ids: [],
                period_definition_ids: [],
            },
            schemaOptions: [],
            schemas: [],
            schema: null,

            // parts
            products: []
        }
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        handleChangeSchema(value) {

            if (!value) {
                return this.schema = null;
            }

            this.schema = this.schemas[value];
        },
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: ''})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));

                    this.schemas = this.form.schemas;
                    this.schemaOptions = this.form.options;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        download() {
            this.loading = true;

            this.form.path = this.path;

            ApiService.store(this.path, this.form)
                .then(({data}) => {
                    this.loading = false;
                    this.openBlankWindow(data.data.link);
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
