export const SET_ERROR = 'setError';
export const SET_PRELOADING = 'setPreLoading';
export const SET_DROPDOWNITEMS = 'setDropdownItems';
export const SET_UI = 'setUi';
export const SET_UI_LANGUAGE = 'setUiLanguage';
export const SET_UI_SETTINGS = 'setUiSettings';
export const SET_UI_CONTACTS = 'setUiContacts';
export const SET_AUTH_TOKEN = 'setAuthToken';
export const DESTROY_AUTH_TOKEN = 'destroyAuthToken';
export const SET_ACCOUNT = 'setAccount';
export const SET_ADDRESSES = 'setAddresses';
export const SET_PUSH_ADDRESS = 'setPushAddress';
export const SET_UPDATE_ADDRESS = 'setUpdateAddress';
export const SET_DESTROY_ADDRESS = 'setDestroyAddress';
export const SET_CHANGE_MAIN_ADDRESS = 'setChangeMainAddress';

// Crud
export const SET_CRUD_PAGINATION = 'setCrudPagination';
export const SET_CRUD_ITEMS = 'setCrudItems';
export const SET_CRUD_ITEM = 'setCrudItem';
export const SET_PUSH_CRUD_ITEM = 'pushCrudItem';
export const SET_UPDATE_CRUD_ITEM = 'updateCrudItem';
export const SET_DESTROY_CRUD_ITEM = 'destroyCrudItem';

