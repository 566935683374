<template>
    <div v-loading="pageLoading">
        <form-box :title="$t('tools.form_messages.title')" icon="android-messages" back_link="/tools/form-messages" :store="update" :loading="loading">
            <el-form ref="form" :model="form">
                <el-form-item :label="$t('inputs.type')" :class="{ 'is-error': submitted && $v.form.alias.$error }">
                    <select-box :options="ui.theme.file_data.form_message_aliases" :container="form" model="alias" :placeholder="$t('inputs.type')"/>
                </el-form-item>

                <template v-for="(meta, metaIndex) in form.inputs">
                    <el-form-item :label="meta.name" :key="metaIndex">
                        <el-input v-model="meta.value" :placeholder="meta.name" />
                    </el-form-item>
                </template>
            </el-form>
        </form-box>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'CrudShow',
    data() {
        return {
            path: 'tools/form-messages',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                path: 'tools/form-messages',
                inputs : []
            }
        }
    },
    validations: {
        form: {
            alias: {required},
            inputs: {},
        }
    },
    computed: mapGetters(['item', 'ui']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        }
    }
}
</script>
