<template>
    <div class="body">
        <div class="box-inline-headline first">E-Posta Ayarları</div>
        <el-form-item label="Varsayilan Gonderici Adresi">
            <el-input v-model="form.mail_from_address" placeholder="Varsayilan Gonderi Adresi" />
        </el-form-item>

        <el-form-item label="Varsayilan Gonderici Adi">
            <el-input v-model="form.mail_from_name" placeholder="Varsayilan Gonderici Adi" />
        </el-form-item>

        <div class="box-inline-headline">Bildirim Ayarları</div>
        <el-form-item label="Form Mesajlarından Gelen Bilgilendirme Mailleri">
            <el-input v-model="form.mail_notifaction_address" placeholder="Form Mesajlarından Gelen Bilgilendirme Mailleri" />
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'EmailSetting',
    props : ['form'],
}
</script>
