<template>
    <div class="error-page error-page-404">
        <div class="wrapper">
            <div class="container">
                <div class="content">
                    <div class="code">404</div>
                    <div class="message">{{ $t('error.title') }}</div>
                    <div class="buttons">
                        <el-button size="large" icon="el-icon-back" style="padding: 15px 30px !important" @click="$router.go(-1)">
                            {{ $t('btn_back') }}
                        </el-button>
                    </div>
                </div>
                <div class="media">
                    <img src="/img/errors/404.svg" alt="error">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error404',
    mounted() {
        //
    }
}
</script>
