<template>
    <div>
        Theme ındex
    </div>
</template>

<script>
export default {
    name: 'Blank',
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
