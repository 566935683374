<template>
    <div class="page">
        <div class="page-body">
            <div class="box">
                <div class="headline">
                    <div class="title">Dosya yöneticisi</div>
                </div>
                <div class="body no-padding">
                    <file-manager :settings="fileManagerSettings" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FileManagerIndex',
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
