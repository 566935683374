<template>
    <div class="vertical-tab-box">
        <el-row :gutter="24">
            <el-col :span="18">
                <slot name="content"></slot>
            </el-col>
            <el-col :span="6">
                <slot name="sidebar"></slot>
                <div class="box vertical-menu">
                    <div class="items" v-if="items">
                        <template v-for="(item, index) in items">
                            <div class="item" :class="{active : activeTab === index}" :key="index" @click="changeTab(index)">
                                {{ item.t }}
                                <icon :source="item.i" v-if="item.i"></icon>
                            </div>
                        </template>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'VerticalTabBox',
        props: {
            items : {
                default : null
            },
            change: {

            }
        },
        data() {
            return {
                activeTab : 0
            }
        },
        methods : {
            changeTab(index) {
                this.activeTab = index;

                return this.change(index)
            }
        }
    };
</script>
