<template>
    <div class="page">
        <div class="page-body">
            <el-form ref="form" :model="form" v-loading="pageLoading">
                <tab-box :titles="['Firma', 'Site', 'İçerik Yönetimi', 'E-Posta', 'Diğer']" :change="handleChangeTab">
                    <template slot="contents">
                        <div class="box margin-top-20">
                            <CompanySetting v-show="activeTab === 0" :form="form" />
                            <SiteSetting v-show="activeTab === 1" :form="form" />
                            <CmsSetting v-show="activeTab === 2" :form="form" />
                            <EmailSetting v-show="activeTab === 3" :form="form" />
                            <OtherSetting v-show="activeTab === 4" :form="form" />

                            <div class="footer large">
                                <el-button icon="el-icon-check" type="success" size="large" class="fluid" @click="store" :loading="loading" :disabled="loading">
                                    {{ $t('btn_save') }}
                                </el-button>
                            </div>
                        </div>
                    </template>
                </tab-box>
            </el-form>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, STORE_CRUD_ITEM} from "@/store/actions.type";
import {SET_UI_SETTINGS} from "@/store/mutations.type";
import CompanySetting from "./Company";
import SiteSetting from "./Site";
import EmailSetting from "./Email";
import OtherSetting from "./Other";
import CmsSetting from "./Cms";

export default {
    name: 'Index',
    components: {CmsSetting, OtherSetting, EmailSetting, SiteSetting, CompanySetting},
    data() {
        return {
            path: 'system/settings',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                //
            },

            activeTab: 0
        }
    },
    computed: mapGetters(['ui', 'item']),
    mounted() {
        this.show();
    },
    methods: {
        handleChangeTab(value) {
            this.activeTab = value;
        },
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: null})
                .then(() => {
                    this.pageLoading = false;

                    if (!this.lodash.size(this.item(this.storePath(this.path)))) {
                        this.form = {
                            //
                        }
                    } else {
                        this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
        store() {
            this.loading = true;

            this.$store
                .dispatch(STORE_CRUD_ITEM, {
                    path: this.path,
                    disable_push: true,
                    settings: this.form
                })
                .then((response) => {
                    this.loading = false;
                    this.$store.commit(SET_UI_SETTINGS, response);
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
