<template>
    <div class="page">
        <div class="page-body">
            <div class="box no-shadow">
                <div class="body no-padding">
                    <div class="box-inline-headline no-margin flex-between">
                        <div class="title">
                            {{ $t('account.address_title') }}
                        </div>
                        <div class="action">
                            <el-tooltip :content="$t('account.add_new_address')" placement="left">
                                <el-button plain size="small" type="primary" icon="el-icon-plus" @click="create" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="body addresses">
                    <empty alias="address"  v-if="!elementSize(account.addresses) && !pageLoading"/>
                    <div class="address" :class="{active : item.is_main, 'is--hide-i' : !item.id}" v-for="(item, index) in account.addresses" :key="index">
                        <el-tooltip :content="item.is_main ? $t('inputs.main_address') : $t('inputs.address')">
                            <div class="media">
                                <icon source="checkbox-blank-circle-outline" v-if="!item.is_main" />
                                <icon source="checkbox-marked-outline" v-if="item.is_main" />
                            </div>
                        </el-tooltip>
                        <div class="text">
                            <div class="title">{{ item.title }}</div>
                            <div class="description">{{ item.address }}</div>
                        </div>
                        <div class="actions">
                            <el-tooltip :content="$t('btn_edit')">
                                <el-button icon="el-icon-edit" plain type="primary" @click="edit(item)" />
                            </el-tooltip>
                            <el-tooltip :content="$t('btn_delete')">
                                <el-button icon="el-icon-delete" plain type="danger" @click="destroy(item.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddressDrawer ref="AddressDrawer" :index="index" type="account" />
    </div>
</template>

<script>
import {GET_ACCOUNT_ADDRESSES,} from "@/store/actions.type";
import {mapState} from "vuex";
import AddressDrawer from "@/app/Components/Account/AddressDrawer";

export default {
    name: 'AddressAccount',
    components: {AddressDrawer},
    data() {
        return {
            pageLoading: false,
        }
    },
    computed: mapState(['account']),
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.$store.dispatch(GET_ACCOUNT_ADDRESSES)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        },

        create() {
            this.$refs.AddressDrawer.create();
        },

        edit(item) {
            this.$refs.AddressDrawer.edit(item.updated);
        },

        destroy(id) {
            this.$refs.AddressDrawer.destroy(id);
        },
    }
}
</script>
