<template>
    <div class="page" v-loading="pageLoading">
        <div class="page-body">
            <div class="box">
                <div class="headline">
                    <div class="title">Tema</div>
                    <div class="actions">
                        <el-tooltip content="Kaydet" placement="bottom">
                            <el-button icon="el-icon-check" type="success" plain size="mini" :loading="loading" :disabled="loading" @click="update" />
                        </el-tooltip>
                    </div>
                </div>
                <el-form ref="form" :model="form" v-if="form.id">
                    <tab-box :titles="['Genel', 'Metinler', 'Bloklar', 'Slider']" :change="handleChangeTab">
                        <template slot="contents">
                            <div class="body">
                                <Default :form="form" v-show="activeTab === 0" />
                                <TextBlock :form="form" v-show="activeTab === 1" />
                                <Block :form="form" v-show="activeTab === 2" />
                                <Slider :form="form" v-show="activeTab === 3" />
                            </div>
                        </template>
                    </tab-box>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import Default from "./Default";
import TextBlock from "./TextBlock";
import Block from "./Block";
import Slider from "./Slider";

export default {
    name: 'ThemeIndex',
    components: {Slider, Block, TextBlock, Default},
    data() {
        return {
            path: 'design/theme',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                //
            },

            activeTab: 0
        }
    },
    computed: mapGetters(['ui', 'item']),
    mounted() {
        this.show();
    },
    methods : {
        handleChangeTab(value) {
            this.activeTab = value;
        },
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: null})
                .then(() => {
                    this.pageLoading = false;

                    if (!this.lodash.size(this.item(this.storePath(this.path)))) {
                        this.form = {
                            //
                        }
                    } else {
                        this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
        update() {
            this.loading = true;

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, {
                    path: this.path,
                    disable_push: true,
                    id : this.ui.theme.id,
                    meta: this.form.meta
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
