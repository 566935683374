<template>
    <div class="page">
        <div class="page-body">
            <div class="box no-shadow">
                <div class="body">
                    <div class="box-inline-headline first">
                        {{ $t('account.me_title_1') }}
                    </div>
                    <el-form :model="form" label-position="top" ref="AppForm" @submit.native.prevent="store">
                        <input type="file" ref="file" style="display: none" class="upload-img" @change="handleChangeAvatar">
                        <el-form-item :label="$t('inputs.your_email')">
                            <el-input v-model="form.email" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_email')" disabled />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.your_name')" :class="{ 'is-error': submitted && $v.form.name.$error }">
                            <el-input v-model="form.name" prefix-icon="el-icon-user" :placeholder="$t('inputs.your_name')" />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.your_phone')" :class="{ 'is-error': submitted && $v.form.phone.$error }" class="no-margin">
                            <el-input v-model="form.phone" prefix-icon="el-icon-phone" :placeholder="$t('inputs.your_phone')" v-mask="'0 (###) ### ## ##'" />
                        </el-form-item>
                    </el-form>
                </div>

                <div class="body">
                    <div class="box-inline-headline first">
                        {{ $t('account.me_title_2') }}
                    </div>
                    <div class="image-upload">
                        <div class="media">
                            <img :src="publicFilePath(avatarPreview)" alt="avatar">
                        </div>
                        <div class="caption">
                            <el-tooltip :content="$t('btn_upload')">
                                <el-button icon="el-icon-upload2" @click="$refs.file.click()" />
                            </el-tooltip>
                            <el-tooltip :content="$t('btn_delete')">
                                <el-button icon="el-icon-delete" type="danger" @click="handleDeleteAvatar" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>

                <div class="body">
                    <el-button type="primary" size="large" class="fluid" @click="store" :loading="loading" :disabled="loading">
                        {{ $t('btn_update') }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {mapState} from "vuex";
import {UPDATE_ACCOUNT} from "@/store/actions.type";

export default {
    name: 'MeAccount',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                avatar: null,
                avatar_file: null
            },
            avatarPreview: '/img/dummy.png'
        }
    },
    validations: {
        form: {
            name: {required, minLength: minLength(2), maxLength: maxLength(191)},
            phone: {required, minLength: minLength(17), maxLength: maxLength(17)},
        }
    },
    computed: mapState(['account']),
    mounted() {
        this.form = this.cloneObjectData(this.account.account);

        if (this.form.avatar) {
            this.avatarPreview = this.form.avatar;
        }
    },
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            let formData = new FormData();

            if (this.$refs.file.files[0]) {
                formData.append('avatar_file', this.$refs.file.files[0]);
            }

            this.lodash.forEach(this.form, (value, key) => {
                formData.append(key, value);
            });

            this.$store
                .dispatch(UPDATE_ACCOUNT, formData)
                .then(() => {
                    this.loading = false;
                    this.submitted = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        handleChangeAvatar() {
            if (!this.$refs.file.files[0]) {
                return false;
            }

            let reader = new FileReader();

            reader.onload = (e) => {
                this.avatarPreview = e.target.result;
            }

            reader.readAsDataURL(this.$refs.file.files[0]);
        },

        handleDeleteAvatar() {
            this.form.avatar = '';
            this.avatarPreview = '/img/dummy.png';
            this.$refs.file.value = null;
        }
    }
}
</script>
