<template>
    <form-box :title="$t('system.users.detail')" icon="account-tie" back_link="/system/users" :store="update" :loading="loading">
        <el-form ref="form" :model="form" v-if="ui.roles">
            <el-form-item :label="$t('inputs.department')" :class="{ 'is-error': submitted && $v.form.role_id.$error }">
                <select-box
                    :options="ui.roles"
                    :container="form"
                    model="role_id"
                    icon="sitemap"
                    :placeholder="$t('inputs.department')"
                />
            </el-form-item>

            <el-form-item :label="$t('inputs.name')" :class="{ 'is-error': submitted && $v.form.name.$error }">
                <el-input v-model="form.name" :placeholder="$t('inputs.name')" prefix-icon="el-icon-user" />
            </el-form-item>

            <el-form-item :label="$t('inputs.email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                <el-input v-model="form.email" :placeholder="$t('inputs.email')" type="email"
                          prefix-icon="el-icon-message" />
            </el-form-item>

            <el-form-item :label="$t('inputs.phone')" :class="{ 'is-error': submitted && $v.form.phone.$error }">
                <el-input v-model="form.phone" prefix-icon="el-icon-phone" :placeholder="$t('inputs.phone')" v-mask="'0 (###) ### ## ##'" />
            </el-form-item>

            <el-form-item :label="$t('inputs.password')" :class="{ 'is-error': submitted && $v.form.password.$error }">
                <el-input v-model="form.password" :placeholder="$t('inputs.password')" type="password"
                          prefix-icon="el-icon-key" show-password />
            </el-form-item>

            <el-alert type="info" :closable="false">
                {{ $t('system.users.update_password_warning') }}
            </el-alert>
        </el-form>
    </form-box>
</template>

<script>
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'CrudShow',
    data() {
        return {
            path: 'system/users',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                path: 'system/users'
            },
        }
    },
    validations: {
        form: {
            role_id: {required},
            name: {required},
            email: {required, email},
            phone: {required, minLength: minLength(17), maxLength: maxLength(17)},
            password: {minLength: minLength(6), maxLength: maxLength(32)},
        }
    },
    computed: mapGetters(['ui', 'item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        }
    }
}
</script>
