<template>
    <div v-loading="pageLoading" style="min-height: 400px">
        <div class="box" v-if="form.id">
            <div class="body list large">
                <div class="li bg-secondary-light">
                    <div class="media large is-image">
                        <img :src="form.image" :alt="form.title">
                    </div>
                    <div class="text">
                        <div class="title">{{ form.title }}</div>
                        <div class="sub-title limited">{{ form.short_description }}</div>
                    </div>
                </div>
            </div>
            <div class="body overflow-500">
                <el-form ref="form" :model="form" :key="formId">
                    <template v-for="(input, index) in form.inputs">
                        <el-form-item :label="input.label" :key="index">
                            <template v-if="input.type === 'string'">
                                <el-input v-model="form.metas[input.name]" :placeholder="input.label" />
                            </template>
                            <template v-if="input.type === 'number'">
                                <el-input v-model="form.metas[input.name]" :placeholder="input.label" type="number" />
                            </template>
                            <template v-if="input.type === 'date'">
                                <el-date-picker v-model="form.metas[input.name]" type="date" :placeholder="input.label" value-format="yyyy-MM-dd" :picker-options="datePickerOptions"/>
                            </template>
                            <template v-if="input.type === 'select'">
                                <select-box :options="input.options" :container="form.metas" :model="input.name" icon="apple-safari" :placeholder="input.label" />
                            </template>

                            <el-alert type="warning" v-if="input.alert" :closable="false" class="in-input">
                                {{ input.alert }}
                            </el-alert>
                        </el-form-item>
                    </template>
                </el-form>
            </div>
            <div class="footer large">
                <el-button type="success" size="large" class="fluid" icon="el-icon-check" @click="update" :disabled="loading" :loading="loading">
                    {{ $t('btn_save') }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'Show',
    data() {
        return {
            path: 'plugins',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                //
            },
            formId : null
        }
    },
    validations() {
        let form = {
            //
        }

        this.lodash.forEach(this.form.inputs, (input) => {
            if (input.type === 'string') {
                //form[input.name] = {required};
            }
        });

        return {
            form: form
        };
    },
    computed: mapGetters(['item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));

                    if (!this.elementSize(this.form.metas)) {
                        this.form.metas = {
                            //
                        };
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            let form = {
                path: this.path,
                id: this.form.id,
                metas: this.form.metas
            }

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        }
    }
}
</script>
