import Vue from "vue";
import VueResource from 'vue-resource'
import JwtService from "../services/jwt.service";
import Config from "../config/app.js";

const ApiService = {
    init() {
        Vue.use(VueResource);
        Vue.http.options.root = Config.API_URI;
    },

    setHeader() {
        Vue.http.headers.common['Authorization'] = 'Bearer ' + JwtService.getToken();
        Vue.http.headers.common['Accept'] = 'application/json';
    },

    query(resource, params) {
        return Vue.http
            .get(resource, params)
            .catch(error => {
                throw new Error(`[RWV] ApiService ${error}`);
            });
    },

    get(resource, params = {}) {
        return Vue.http.get(resource, params);
    },

    store(resource, params = {}, headers = {}) {
        return Vue.http.post(resource, params, headers);
    },

    update(resource, params = {}) {
        return Vue.http.put(resource, params);
    },

    destroy(resource, params = {}) {
        return Vue.http.delete(resource, params);
    }
};

export default ApiService;
