<template>
    <div>
        <template v-for="(text, index) in form.file_data.texts">
            <div :key="text.value">
                <div class="box-inline-headline" :class="{first : !index}">{{ text.label }}</div>
                <el-form-item>
                    <language-input type="text" :items="form.meta[text.value]" :model="text.value" />
                </el-form-item>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'TextBlock',
    props : ['form'],
}
</script>
