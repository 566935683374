<template>
    <div>

    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props : ['id'],
    data() {
        return {
            items : []
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
