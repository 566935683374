import AccountLayout from "@/app/Layouts/AccountLayout";
import ViewContainer from "@/app/Containers/ViewContainer";

// Pages
import Logout from "@/app/Pages/Account/Logout";

// Account
import Me from "@/app/Pages/Account/Me";
import Password from "@/app/Pages/Account/Password";
import Address from "@/app/Pages/Account/Address";


export default {
    path: '/account',
    component: AccountLayout,
    meta: {
        auth: true,
        title: 'Hesabım',
    },
    children: [
        {
            name: 'app.account.logout.index',
            path: 'logout',
            component: Logout,
            meta: {
                auth: true,
                title: 'Çıkış',
                layout: 'content'
            },
        },
        {
            name: 'app.account.me.index',
            path: 'me',
            component: Me,
            meta: {
                auth: true,
                title: 'Hesap Bilgilerim',
                bodyClass: 'account-page',
            },
        },
        {
            name: 'app.account.password.index',
            path: 'password',
            component: Password,
            meta: {
                auth: true,
                title: 'Şifre',
                bodyClass: 'account-page',
            },
        },
        {
            name: 'app.account.addresses.index',
            path: 'addresses',
            component: Address,
            meta: {
                auth: true,
                title: 'Adres Bilgilerim',
                bodyClass: 'account-page',
            },
        }
    ]
}
