<template>
    <el-select v-model="container[model]" :placeholder="placeholder" :multiple="multiple" :clearable="clearable" filterable @change="handleChange">
        <el-option v-for="(option, index) in items" :label="option[label]" :value="valuetype === 'string' ? String(option[value]) : option[value]" :key="index" />
        <template slot="prefix">
            <icon :source="icon" class="el-input__icon" />
        </template>
    </el-select>
</template>

<script>
import {mapGetters} from "vuex";
import SET_DROPDOWNITEMS from "@/store/mutations.type";
import ApiService from "@/services/api.service";

export default {
    name: 'SelectBox',
    props: {
        icon: {
            type: String,
            default: 'filter-outline'
        },
        options: {
           //
        },
        container: {
            default: {}
        },
        model: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Seçiminiz'
        },
        clearable: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'title'
        },
        value: {
            type: String,
            default: 'id'
        },
        valuetype: {
            type: String,
            default: 'int'
        },
        remote: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        },
        changeFull: {
            type: Boolean,
            default: false
        },
        change: {
            type: Function,
        },
    },
    data() {
        return {
            items : []
        }
    },
    computed: mapGetters(['dropddownItems']),
    created() {
        if (!this.remote) {
            this.items = this.elementSize(this.options) ? this.options : [];
        } else {
            this.remoteItems();
        }
    },
    methods: {
        remoteItems() {
            ApiService.get(this.remote)
                .then(({data}) => {
                    this.items = data.data;

                    this.$store.commit(SET_DROPDOWNITEMS, {
                        index : this.remote,
                        data : data.data
                    })
                });
        },

        handleChange(value) {
            if (!this.change) {
                return false;
            }

            if (!this.changeFull) {
                return this.change(value)
            }

            return this.change(this.lodash.filter(this.items, (o) => {return o.id == value})[0])
        }
    },
};
</script>
