<template>
    <div v-loading="pageLoading">
        <div class="box margin-bottom-40">
            <div class="headline">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip :content="$t('btn_back')" placement="bottom">
                        <el-button icon="el-icon-back" plain size="mini" @click="goRoute('/cms/' + $route.meta.alias + '/categories')" />
                    </el-tooltip>
                    <el-tooltip content="Kaydet" placement="bottom">
                        <el-button icon="el-icon-check" type="success" plain size="mini" @click="crudProcess" :loading="loading" :disabled="loading" />
                    </el-tooltip>
                </div>
            </div>
        </div>

        <vertical-tab-box
            :items="[{t : 'Metin', i : 'text-box-outline'}, {t : 'Özellikler', i : 'feature-search-outline'}, {t : 'Sayfa Görünümü', i : 'palette-outline'}, {t : 'Seo', i : 'xml'}]"
            :change="handleTabChange"
        >
            <div slot="content">
                <el-form ref="form" :model="form">
                    <div v-show="activeTab === 0">
                        <div class="box medium-size">
                            <div class="headline">
                                <div class="title">Metin</div>
                            </div>
                            <div class="body">
                                <el-form-item label="Başlık">
                                    <language-input type="string" :items="form.texts" model="title" />
                                </el-form-item>

                                <el-form-item label="Kısa Açıklama">
                                    <language-input type="text" :items="form.texts" model="short_description" />
                                </el-form-item>

                                <el-form-item label="Açıklama">
                                    <language-input type="editor" :items="form.texts" model="description" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div v-show="activeTab === 1">
                        <div class="box medium-size">
                            <div class="headline">
                                <div class="title">Özellikler</div>
                            </div>
                            <div class="body">
                                <el-form-item label="Bağlı Olduğu Kategori">
                                    <select-box :remote="'cms/categories?filter[alias]=' + $route.meta.alias" :container="form" model="parent_id" placeholder="Bağlı Olduğu Kategori"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div v-show="activeTab === 2">
                        <div class="box medium-size">
                            <div class="headline">
                                <div class="title">Tasarım</div>
                            </div>
                            <div class="body">
                                <div class="box-inline-headline first">Medya</div>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <el-form-item label="Kapak Resmi">
                                            <fs v-model="form.file.path" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <div class="box-inline-headline">Özellikler</div>
                                <el-form-item label="İçerik Sayısı">
                                    <el-input v-model="form.per_page_limit" placeholder="İçerik Sayısı" type="number" />
                                </el-form-item>

                                <el-form-item label="İçerik Sıralama Türü">
                                    <select-box :options="sorts" :container="form" model="sort_type" placeholder="İçerik Sıralama Türü"/>
                                </el-form-item>

                                <el-form-item label="Şablon" class="margin-bottom-0">
                                    <el-input v-model="form.view_path" placeholder="Şablon" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div v-show="activeTab === 3">
                        <div class="box medium-size">
                            <div class="headline">
                                <div class="title">Seo</div>
                            </div>
                            <div class="body">
                                <Seo :items="form.seos" />
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
        </vertical-tab-box>
    </div>
</template>

<script>
import {STORE_CRUD_ITEM, GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Seo from "../../../Components/Seo";

export default {
    name: 'Crud',
    components: {Seo},
    data() {
        return {
            path: 'cms/categories',
            pageLoading: false,
            loading: false,
            submitted: false,
            activeTab : 0,
            form: {
                path : 'cms/categories',
                meta: {
                    //
                },
                file: {
                    //
                },
                seos: [],
                texts: [],
            },
            categories : [],
            sorts: [
                {
                    id: 'created_at_desc',
                    title: 'Olusturulma Tarihine Gore: Azalan',
                },
                {
                    id: 'created_at_asc',
                    title: 'Olusturulma Tarihine Gore: Artan',
                },
                {
                    id: 'updated_at_desc',
                    title: 'Guncellenme Tarihine Gore: Azalan',
                },
                {
                    id: 'updated_at_asc',
                    title: 'Guncellenme Tarihine Gore: Artan',
                },
                {
                    id: 'sort_desc',
                    title: 'Siralama Numarasina Gore: Azalan',
                },
                {
                    id: 'sort_asc',
                    title: 'Siralama Numarasina Gore: Artan',
                },
                {
                    id: 'publish_at_desc',
                    title: 'Yayinlanma Tarihine Gore: Azalan',
                },
                {
                    id: 'publish_at_asc',
                    title: 'Yayinlanma Tarihine Gore: Artan',
                }
            ]
        }
    },
    validations: {
        form: {
            //
        }
    },
    computed: mapGetters(['ui', 'item']),
    mounted() {
        if (this.$route.params.id) {
            this.show();
        } else {
            this.initLanguageWithTextForm(this.form.texts);
            this.initLanguageWithTextForm(this.form.seos);
        }
    },
    methods: {

        handleTabChange(index) {
            this.activeTab = index;
        },

        crudProcess() {
            if (this.$route.params.id) {
                return this.update();
            }

            return this.store();
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path
            this.form.alias = this.$route.meta.alias

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;

                    setTimeout(() => {
                        this.loading = false;
                        this.goRoute('/cms/'+this.$route.meta.alias+'/categories/' + response.id)
                    }, 1000)
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));

                    if (!this.form.file) {
                        this.form.file = {
                            //
                        }
                    }

                    if (!this.form.meta) {
                        this.form.meta = {
                            //
                        }
                    }
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;

                    this.show();
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        }
    }
}
</script>
