<template>
    <div class="box margin-top-40">
        <div class="headline">
            <div class="title">
                {{ $t('dashboard.last_messages') }}
            </div>
        </div>
        <div class="body list" v-loading="loading">
            <empty v-if="!elementSize(items(storePath(path))) && !loading" />
            <template v-if="items(storePath(path))">
                <div class="li cursor-pointer" v-for="(item, index) in items(storePath(path))" :key="index">
                    <div class="text" @click="goRoute('/tools/form-messages/' + item.id)">
                        <div class="title">
                            {{ item.name }}
                        </div>
                        <div class="metas">
                            <template v-for="(alias, aliasIndex) in ui.theme.file_data.form_message_aliases">
                                <div class="meta" :key="aliasIndex" v-if="item.alias === alias.id">
                                    {{ alias.title }}
                                </div>
                            </template>
                            <div class="meta">
                                {{ item.phone }}
                            </div>
                            <div class="meta">
                                {{ item.email }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'FormMessage',
    data() {
        return {
            path: 'tools/form-messages',
            loading: false,
            filter: {
                //
            },
            page: 1
        }
    },
    computed: mapGetters(['pagination', 'items', 'ui']),
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.loading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
