<template>
    <div class="page">
        <div class="page-body">
            <div class="box">
                <div class="headline large">
                    <div class="title">
                        <icon :source="icon" v-if="icon" />
                        {{ title }}
                    </div>
                    <div class="actions">
                        <el-tooltip :content="$t('btn_back')" placement="bottom">
                            <el-button icon="el-icon-back" plain size="mini" @click="goRoute(back_link)" />
                        </el-tooltip>
                        <el-tooltip content="Kaydet" placement="bottom">
                            <el-button icon="el-icon-check" type="success" plain size="mini" @click="store" :loading="loading" :disabled="loading" v-if="store" />
                        </el-tooltip>
                    </div>
                </div>
                <slot name="filter"></slot>
                <div class="body" :class="bodyClass">
                    <slot></slot>
                </div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormBox',
        props: {
            title : {
                default : null
            },
            icon : {
                default : null
            },
            back_link : {
                default : null
            },
            store : {
                //
            },
            loading : {
                default : false
            },
            bodyClass : {
                default : false
            },
        },
        methods : {
            //
        }
    };
</script>
