<template>
    <div class="pagination" v-if="pagination(storePath(path)) && pagination(storePath(path)).total > pagination(storePath(path)).per_page">
        <el-pagination background layout="prev, pager, next" :current-page="page" :total="pagination(storePath(path)).total" :page-size="pagination(storePath(path)).per_page" @current-change="change" />
    </div>
</template>

<script>
export default {
    name: 'PagePagination',
    props: ['path', 'page', 'pagination', 'change'],
};
</script>
