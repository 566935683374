<template>
    <div class="dashboard" v-loading="pageLoading">
        <div class="stats">
            <el-row :gutter="24">
                <el-col :span="8">
                    <div class="box stat">
                        <div class="text">
                            <div class="name">{{ $t('dashboard.page_count') }}</div>
                            <div class="count">{{ response.counter.page_count }}</div>
                        </div>
                        <div class="media bg-green-light">
                            <icon source="note-text-outline" class="text-green" />
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="box stat">
                        <div class="text">
                            <div class="name">{{ $t('dashboard.page_view') }}</div>
                            <div class="count">{{ response.counter.page_view }}</div>
                        </div>
                        <div class="media bg-yellow-light">
                            <icon source="eye-outline" class="text-yellow" />
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="box stat">
                        <div class="text">
                            <div class="name">{{ $t('dashboard.message_count') }}</div>
                            <div class="count">{{ response.counter.message_count }}</div>
                        </div>
                        <div class="media bg-blue-light">
                            <icon source="form-select" class="text-blue" />
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="box margin-top-40">
            <div class="headline">
                <div class="title">
                    {{ $t('dashboard.monthly_stat_title', {month : response.month_name}) }}
                </div>
            </div>
            <div class="body no-padding">
                <highcharts :options="chartOptions"></highcharts>
            </div>
        </div>

        <div class="margin-top-40">
            <FormMessage />
        </div>
    </div>
</template>

<script>
import {GET_CRUD_ITEMS} from "@/store/actions.type";
import FormMessage from "./FormMessage";

export default {
    name: 'DashboardIndex',
    components : {
        FormMessage
    },
    data() {
        return {
            path: 'dashboard',
            pageLoading : false,
            response : {
                month_name : null,
                counter : {
                    //
                },
                chart : {
                    categories : [],
                    series : [],
                }
            },
            chartOptions: {
                credits: false,
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                xAxis: {
                    categories: []
                },
                series: []
            }
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: null, page: 1})
                .then((response) => {
                    this.pageLoading = false;
                    this.response = response;

                    this.chartOptions.xAxis.categories = response.chart.categories;

                    this.lodash.forEach(response.chart.series, (item) => {
                        this.chartOptions.series.push({
                            name : this.$t(item.name),
                            data : item.data,
                            color : item.color,
                        })
                    })
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        }
    }
}
</script>
