<template>
    <div class="body">
        <div class="box-inline-headline first">Bakım Modu</div>

        <el-form-item label="Durum">
            <select-box :container="form" model="maintenance_status" :options="approveOptions" />
        </el-form-item>
        <el-form-item label="Mesaj">
            <el-input type="textarea" v-model="form.maintenance_message" placeholder="Mesaj" />
        </el-form-item>

        <div class="box-inline-headline">Yönlendirme Modu</div>

        <el-form-item label="Durum">
            <select-box :container="form" model="redirect_status" :options="approveOptions" />
        </el-form-item>
        <el-form-item label="Yönlendirilecek Domain">
            <el-input type="textarea" v-model="form.redirect_url" placeholder="Yönlendirilecek Domain" />
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'OtherSetting',
    props : ['form'],
}
</script>
