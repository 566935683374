// Utils
import lodash from 'lodash';
import moment from 'moment';

// Vue
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 3. party
import Vuelidate from 'vuelidate'
import ElementUI from 'element-ui';
import ElementUILocale from 'element-ui/lib/locale'
import ElementUILang from 'element-ui/lib/locale/lang/tr-TR'
import VueTheMask from 'vue-the-mask'
import VuePageTransition from 'vue-page-transition'
import HighchartsVue from 'highcharts-vue'
import FileManager from 'laravel-file-manager'

// Misc
import "./utils/RegisterServiceWorker";
import store from './store'
import router from './routes/'
import {Languages} from './resources/lang';
import './components'
import ApiService from "./services/api.service";
import Config from "./config/app";
import JwtService from "./services/jwt.service";

// Set
ApiService.init();
ElementUILocale.use(ElementUILang);

// Init
Vue.use(VueI18n);
Vue.use(ElementUI, {ElementUILocale});
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VuePageTransition);
Vue.use(HighchartsVue);
Vue.use(FileManager, {
    store,
    headers: {
        'Authorization': 'Bearer ' + JwtService.getToken(),
    },
    baseUrl: Config.API_URI + '/tools/file-manager/'
});

//require('./utils/Urlify')

// Config
Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.prototype.lodash = lodash;
Vue.prototype.moment = moment;

Vue.mixin({
    data: () => {
        return {
            datePickerOption: {
                firstDayOfWeek: 1,
            },
            confirmOption: {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            },
            predefineColors: [
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                '#5c50b9',
            ],
            datePickerOptions: {
                firstDayOfWeek: 1,
            },
            genders: [
                {
                    id: 'e',
                    title: 'Erkek'
                },
                {
                    id: 'k',
                    title: 'Kadin'
                }
            ],
            fileManagerSettings: {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${JwtService.getToken()}`,
                },
                baseUrl: Config.API_URI + '/tools/file-manager/',
                viewType: 'grid'
            },
            approveOptions : [
                {
                    id : 'yes',
                    title : 'Aktif'
                },
                {
                    id : 'no',
                    title : 'Pasif'
                }
            ]
        }
    },
    methods: {
        openBlankWindow(link) {
            return window.open(link, '_blank');
        },

        elementSize(items) {
            return this.lodash.size(items)
        },

        goRoute(link) {
            if (this.$route.path === link) {
                return null;
            }

            return this.$router.push(link);
        },

        goRouteExternal(link) {
            window.location = link;
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },

        moneyFormat(money) {

            let formattedOutput = new Intl.NumberFormat('tr-TR', {
                style: 'currency',
                currency: 'TRY',
                minimumFractionDigits: 2,
            });

            return formattedOutput.format(money)
        },

        cloneObjectData(data) {
            return JSON.parse(JSON.stringify(data));
        },

        publicFilePath(path) {
            if ((path).slice(0, 4) === 'data') {
                return path;
            }

            return Config.BASE_URI + '/' + path;
        },

        publicSiteLink(path) {
            return Config.BASE_URI + '/' + path;
        },

        storePath(payload) {
            let paths = payload.split('/')

            return paths.join('_');
        },

        imageLoad(url) {
            let status = null;
            let image = new Image();

            image.src = url;

            image.onload = () => {
                status = url;
            };

            image.onerror = () => {
                status = null
            };

            return status;
        },

        initLanguageWithTextForm(container) {
            this.lodash.forEach(this.$store.getters.ui.languages, (lang) => {
                let schema = {
                    language_id : lang.id,
                    language_iso_code : lang.iso_code,
                    language_title : lang.title,
                }

                container.push(schema);
            });

            return container;
        }
    }
});

// App
import Bootstrap from './Bootstrap.vue'
import {getToken} from "./services/jwt.service";

new Vue({
    router,
    store,
    i18n: new VueI18n({
        locale: 'tr-TR',
        silentTranslationWarn: true,
        messages: Languages,
        dateTimeFormats: {
            'tr-TR': {
                short: {
                    year: 'numeric', month: 'short', day: 'numeric'
                },
                long: {
                    year: 'numeric', month: 'short', day: 'numeric',
                    weekday: 'short', hour: 'numeric', minute: 'numeric'
                }
            }
        },
        numberFormats: {
            'en-Us': {
                currency: {
                    style: 'currency', currency: 'TRY', symbol: '₺'
                }
            },
            'tr-TR': {
                currency: {
                    style: 'currency', currency: 'TRY', symbol: '₺'
                }
            }
        }
    }),
    replace: false,
    render: h => h(Bootstrap),
}).$mount('#Bootstrap');
