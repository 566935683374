import ApiService from '../services/api.service';

import {
    GET_UI,
    GET_UI_CONTACTS,
} from "./actions.type";

import {
    SET_ERROR,
    SET_PRELOADING,
    SET_UI,
    SET_UI_LANGUAGE,
    SET_UI_SETTINGS,
    SET_UI_CONTACTS,
} from "./mutations.type";


const state = {
    ui: null,
    contacts: [],
};

const getters = {
    ui(state) {
        return state.ui;
    },

    contacts(state) {
        return state.contacts;
    },
};

const mutations = {
    [SET_UI](state, payload) {
        state.ui = payload;
    },

    [SET_UI_SETTINGS](state, payload) {
        state.ui.settings = payload;
    },

    [SET_UI_LANGUAGE](state, payload) {
        state.ui.language = payload;
    },

    [SET_UI_CONTACTS](state, payload) {
        state.contacts = payload;
    },
};

const actions = {
    [GET_UI](context) {

        context.commit(SET_PRELOADING, true);

        return new Promise(resolve => {
            ApiService.get('ui')
                .then(({data}) => {
                    context.commit(SET_UI, data.data);
                    resolve(data);
                    context.commit(SET_PRELOADING, false);

                    if (window.location.pathname === '/maintenance') {
                        window.location = '/'
                    }
                })
                .catch(() => {
                    if (window.location.pathname !== '/maintenance') {
                        window.location = '/maintenance'
                    } else {
                        context.commit(SET_PRELOADING, false);
                    }
                });
        });
    },

    [GET_UI_CONTACTS](context, title) {
        return new Promise(resolve => {
            ApiService.get('search/contacts', {params : {q : title}})
                .then(({data}) => {
                    context.commit(SET_UI_CONTACTS, data.data);
                    resolve(data.data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response.body);
                });
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
