<template>
    <div class="auth">
        <div class="background"></div>
        <div class="auth-wrp">
            <div class="auth-container">
                <div class="box">
                    <div class="box-body">
                        <div class="column">
                            <div class="column-wrp content">
                                <div class="head">
                                    {{ $t('auth.login_title') }}
                                    <div class="sub">{{ $t('auth.login_description') }}</div>
                                </div>
                                <el-form :model="form" label-position="top" ref="AuthForm" @submit.native.prevent="store">
                                    <el-form-item :label="$t('inputs.your_email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                                        <el-input v-model="form.email" type="email" prefix-icon="el-icon-message" :placeholder="$t('inputs.your_email')" class="large" />
                                    </el-form-item>
                                    <el-form-item :label="$t('inputs.your_password')" :class="{ 'is-error': submitted && $v.form.password.$error }">
                                        <el-input type="password" v-model="form.password" prefix-icon="el-icon-key" :placeholder="$t('inputs.your_password')" class="large" show-password />
                                    </el-form-item>

                                    <el-button type="primary" class="fluid margin-top-30" size="large" @click="store" :loading="loading" :disabled="loading">
                                        {{ $t('btn_login') }}
                                    </el-button>
                                </el-form>
                            </div>
                        </div>
                        <div class="column cover">
                            <div class="column-wrp">
                                <img src="/img/logo.svg" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {required, email, minLength, maxLength} from "vuelidate/lib/validators";
import {STORE_LOGIN} from "@/store/actions.type";
import {SET_PRELOADING} from "@/store/mutations.type";
import JwtService from '@/services/jwt.service';

export default {
    name: 'LoginAuth',
    data() {
        return {
            loading: false,
            submitted: false,
            form: {
                //
            }
        }
    },
    validations: {
        form: {
            email: {required, email},
            password: {required, minLength: minLength(6), maxLength: maxLength(32)},
        }
    },
    computed: mapGetters(['ui']),
    mounted() {
        if (this.$route.query.token) {
            this.redirectLogin();
        }
    },
    methods: {
        store() {

            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch(STORE_LOGIN, this.form)
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        redirectLogin() {
            JwtService.storeToken(this.$route.query.token);
            this.$store.commit(SET_PRELOADING, true);

            setTimeout(() => {
                window.location = '/dashboard';
            }, 500)
        }
    }
}
</script>
