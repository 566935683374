<template>
    <div class="form-item-capsule">
        <el-select v-model="container[model]" :placeholder="placeholder" :multiple="multiple" clearable filterable @change="handleChange">
            <el-option v-for="(option, index) in items(storePath(path))" :label="option.title" :value="option.id" :key="index" />
            <template slot="prefix">
                <icon :source="icon" class="el-input__icon" />
            </template>
        </el-select>

        <el-tooltip :content="$t('btn_edit')" placement="bottom">
            <el-button icon="el-icon-s-operation" size="large" class="capsule-button" type="basic" @click="indexDialogVisible = true" />
        </el-tooltip>

        <el-dialog :title="$t('inputs.addresses')" :visible.sync="indexDialogVisible" width="60%" append-to-body class="no-padding">
            <div class="box no-padding no-shadow" v-loading="loading">
                <div class="body addresses overflow-300">
                    <div class="address" v-for="item in items(storePath(path))" :key="item.id" :class="{active : item.is_main, 'is--hide-i' : !item.id}">
                        <el-tooltip :content="item.is_main ? $t('inputs.main_address') : $t('inputs.address')">
                            <div class="media">
                                <icon source="checkbox-blank-circle-outline" v-if="!item.is_main" />
                                <icon source="checkbox-marked-outline" v-if="item.is_main" />
                            </div>
                        </el-tooltip>
                        <div class="text">
                            <div class="title">{{ item.title }}</div>
                            <div class="description">{{ item.name }}, {{ item.address }}</div>
                        </div>
                        <div class="actions inline visible">
                            <el-tooltip content="Duzenle" placement="bottom">
                                <el-button icon="el-icon-edit" type="primary" plain class="is-dialog" @click="edit(item)" />
                            </el-tooltip>
                            <el-tooltip content="Sil" placement="bottom">
                                <el-button icon="el-icon-delete" type="danger" plain class="is-dialog" @click="destroy(item.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <template slot="footer" class="dialog-footer">
                <el-button type="primary" class="fluid" size="large" @click="create">
                    {{ $t('account.add_new_address') }}
                </el-button>
            </template>
        </el-dialog>

        <el-dialog :title="$t('account.address_detail')" :visible.sync="dialogVisible" width="60%" append-to-body class="no-padding">
            <div class="box no-shadow" v-loading="loading">
                <div class="body">
                    <el-form ref="form" :model="form">
                        <el-form-item>
                            <el-switch v-model="form.is_main" :active-text="$t('inputs.main_address')" />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.address_title')" :class="{ 'is-error': submitted && $v.form.title.$error }">
                            <el-input v-model="form.title" :placeholder="$t('inputs.address_title')" />
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item :label="$t('inputs.first_name')" :class="{ 'is-error': submitted && $v.form.first_name.$error }">
                                    <el-input v-model="form.first_name" :placeholder="$t('inputs.first_name')" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('inputs.last_name')" :class="{ 'is-error': submitted && $v.form.last_name.$error }">
                                    <el-input v-model="form.last_name" :placeholder="$t('inputs.last_name')" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item :label="$t('inputs.identification_no')" :class="{ 'is-error': submitted && $v.form.identification_no.$error }">
                            <el-input v-model="form.identification_no" :placeholder="$t('inputs.identification_no')" v-mask="'###########'" />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.phone')" :class="{ 'is-error': submitted && $v.form.phone.$error }" :key="form.id">
                            <el-input v-model="form.phone" :placeholder="$t('inputs.phone')" v-mask="'0 (###) ### ## ##'" />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                            <el-input v-model="form.email" :placeholder="$t('inputs.email')" type="email" />
                        </el-form-item>

                        <zones :form="form" :key="'zone-' + form.id" />

                        <el-form-item :label="$t('inputs.address_1')" :class="{ 'is-error': submitted && $v.form.address_1.$error }">
                            <el-input v-model="form.address_1" :placeholder="$t('inputs.address_1')" />
                        </el-form-item>

                        <el-form-item :label="$t('inputs.address_2')" :class="{ 'is-error': submitted && $v.form.address_2.$error }">
                            <el-input v-model="form.address_2" :placeholder="$t('inputs.address_2')" />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <template slot="footer" class="dialog-footer">
                <el-button type="success" class="fluid" size="large" @click="store" v-if="!form.id" v-loading="loading" :disabled="loading">
                    {{ $t('btn_save') }}
                </el-button>
                <el-button type="success" class="fluid" size="large" @click="update" v-if="form.id" v-loading="loading" :disabled="loading">
                    {{ $t('btn_update') }}
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {DESTROY_CRUD_ITEM, GET_CRUD_ITEMS, STORE_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
    name: 'StatusBox',
    props: {
        icon: {
            type: String,
            default: 'map-marker-outline'
        },
        container: {
            type: Object,
        },
        model: {
            type: String,
            default: 'address_id'
        },
        placeholder: {
            type: String,
            default: 'Seçiminiz'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        change: {
            type: Function,
        },
        filter: {
            type: Object,
        },
    },
    data() {
        return {
            path: 'tenants/tools/addresses',
            loading: false,
            submitted: false,
            page : 1,
            form: {
                //
            },

            indexDialogVisible: false,
            dialogVisible : false,
        }
    },
    validations: {
        form: {
            country_zone_id: {required},
            city_zone_id: {required},
            town_zone_id: {required},

            identification_no: {required, minLength: minLength(11), maxLength: maxLength(11)},
            title: {required, minLength: minLength(2), maxLength: maxLength(191)},
            first_name: {required},
            last_name: {required},

            phone: {required, minLength: minLength(17), maxLength: maxLength(17)},
            email: {required, email},
            address_1: {required},
            address_2: {},
            is_main: {},
            company_name: {},
            tax_no: {},
            tax_center: {},
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {
        this.index();
    },
    methods: {
        handleChange(value) {
            if (!this.change) {
                return false;
            }

            return this.change(value)
        },

        index() {
            if (this.items(this.storePath(this.path))) {
                return [];
            }

            this.loading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {path: this.path, filter: this.filter, page: this.page})
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        },

        create() {
            this.dialogVisible = true;
            this.form = {
                //
            }
        },

        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path;

            if (this.filter.user_id) {
                this.form.user_id = this.filter.user_id
            }

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        edit(item) {
            this.dialogVisible = true;
            this.form = this.cloneObjectData(item.updated);
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            if (this.filter.user_id) {
                this.form.user_id = this.filter.user_id
            }

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                    this.dialogVisible = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },

        destroy(id) {
            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.loading = true;

                    this.$store
                        .dispatch(DESTROY_CRUD_ITEM, {id: id, path: this.path})
                        .then(() => {
                            this.loading = false;
                            this.page = 1;
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },
    },
};
</script>
