import PageLayout from "@/app/Layouts/PageLayout";

// Pages
import Index from "@/app/Pages/Plugins/Index";
import Show from "@/app/Pages/Plugins/Show";

export default {
    path: '/plugins',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Eklentiler',
    },
    children: [
        {
            name: 'app.plugins.index',
            path: '',
            component: Index,
            meta: {
                auth: true,
                title: 'Eklentiler',
            },
        },
        {
            name: 'app.plugins.show',
            path: ':id',
            component: Show,
            meta: {
                auth: true,
                title: 'Eklenti Bilgileri',
            },
        }
    ]
}
