import ViewContainer from "@/app/Containers/ViewContainer";

// Pages
import Login from "@/app/Pages/Auth/Login";

export default {
    path: '/auth',
    component: ViewContainer,
    meta: {
        auth: true,
        title: 'Hesap İşlemleri',
    },
    children: [
        {
            name: 'app.auth.login.index',
            path: 'login',
            component: Login,
            meta: {
                auth: true,
                title: 'Giriş',
                layout : 'content'
            },
        }
    ]
}
