let domain = (window.location.hostname).replace('panel.', '');
let apiUri = domain;

if (apiUri === 'onofset.com') {
    apiUri = 'yeni.onofset.com'
}

export const config = {
    BASE_URI: process.env.VUE_APP_BASE_URI ? process.env.VUE_APP_BASE_URI : 'https://' + domain,
    APP_BASE_URI: process.env.VUE_APP_APP_BASE_URI ? process.env.VUE_APP_APP_BASE_URI : 'https://panel.' + domain,
    API_URI: process.env.VUE_APP_API_URI ? process.env.VUE_APP_API_URI : 'https://'+ apiUri +'/api/v1',
}

export default config;
