<template>
    <div v-loading="pageLoading">
        <form-box :title="$t('design.menus.show')" back_link="/design/menus" :store="update" :loading="loading">
            <el-form ref="form" :model="form">

                <el-form-item label="Başlık" :class="{ 'is-error': submitted && $v.form.title.$error }">
                    <el-input v-model="form.title" placeholder="Başlık" />
                </el-form-item>

                <el-form-item label="Şablon" :class="{ 'is-error': submitted && $v.form.view_path.$error }" class="margin-bottom-0">
                    <el-input v-model="form.view_path" placeholder="Şablon" />
                </el-form-item>
            </el-form>
        </form-box>

        <MenuLink :id="form.id" v-if="form.id" />
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";
import MenuLink from "./Link";

export default {
    name: 'CrudShow',
    components: {MenuLink},
    data() {
        return {
            path: 'design/menus',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                path: 'design/menus',
            }
        }
    },
    validations: {
        form: {
            title: {required},
            view_path: {required},
        }
    },
    computed: mapGetters(['item', 'ui']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },

        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        }
    }
}
</script>
