<template>
    <vue-page-transition>
        <router-view :key="$route.fullPath" />
    </vue-page-transition>
</template>

<script>
export default {
    name: 'ViewContainer',
    mounted() {
        //
    }
}
</script>
