import PageLayout from "@/app/Layouts/PageLayout";
import ViewContainer from "@/app/Containers/ViewContainer";

// Categories
import CategoryIndex from "@/app/Pages/Catalog/Categories/Index";
import CategoryCrud from "@/app/Pages/Catalog/Categories/Crud";

// Products
import ProductIndex from "@/app/Pages/Catalog/Products/Index";
import ProductCrud from "@/app/Pages/Catalog/Products/Crud";

// Brands
import BrandIndex from "@/app/Pages/Catalog/Brands/Index";
import BrandCreate from "@/app/Pages/Catalog/Brands/Create";
import BrandShow from "@/app/Pages/Catalog/Brands/Show";

// Features
import FeatureIndex from "@/app/Pages/Catalog/Features/Index";
import FeatureCreate from "@/app/Pages/Catalog/Features/Create";
import FeatureShow from "@/app/Pages/Catalog/Features/Show";

export default {
    path: '/catalog',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Katalog',
    },
    children: [
        {
            path: 'categories',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Kategoriler',
            },
            children : [
                {
                    name: 'app.catalog.categories.index',
                    path: '',
                    component: CategoryIndex,
                    meta: {
                        auth: true,
                        title: 'Kategoriler',
                        alias : 'products'
                    },
                },
                {
                    name: 'app.catalog.categories.create',
                    path: 'create',
                    component: CategoryCrud,
                    meta: {
                        auth: true,
                        title: 'Kategori Oluştur',
                        alias : 'products'
                    },
                },
                {
                    name: 'app.catalog.categories.show',
                    path: ':id',
                    component: CategoryCrud,
                    meta: {
                        auth: true,
                        title: 'Kategori Bilgileri',
                        alias : 'products'
                    },
                }
            ]
        },
        {
            path: 'products',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Ürünler',
            },
            children : [
                {
                    name: 'app.catalog.products.index',
                    path: '',
                    component: ProductIndex,
                    meta: {
                        auth: true,
                        title: 'Ürünler',
                        alias : 'products'
                    },
                },
                {
                    name: 'app.catalog.products.create',
                    path: 'create',
                    component: ProductCrud,
                    meta: {
                        auth: true,
                        title: 'Ürün Oluştur',
                        alias : 'products'
                    },
                },
                {
                    name: 'app.catalog.products.show',
                    path: ':id',
                    component: ProductCrud,
                    meta: {
                        auth: true,
                        title: 'Ürün Bilgileri',
                        alias : 'products'
                    },
                }
            ]
        },
        {
            path: 'brands',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Markalar',
            },
            children : [
                {
                    name: 'app.catalog.brands.index',
                    path: '',
                    component: BrandIndex,
                    meta: {
                        auth: true,
                        title: 'Markalar',
                    },
                },
                {
                    name: 'app.catalog.brands.create',
                    path: 'create',
                    component: BrandCreate,
                    meta: {
                        auth: true,
                        title: 'Marka Oluştur',
                    },
                },
                {
                    name: 'app.catalog.brands.show',
                    path: ':id',
                    component: BrandShow,
                    meta: {
                        auth: true,
                        title: 'Marka Bilgileri',
                    },
                }
            ]
        },
        {
            path: 'features',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Özellikler',
            },
            children : [
                {
                    name: 'app.catalog.features.index',
                    path: '',
                    component: FeatureIndex,
                    meta: {
                        auth: true,
                        title: 'Özellikler',
                    },
                },
                {
                    name: 'app.catalog.features.create',
                    path: 'create',
                    component: FeatureCreate,
                    meta: {
                        auth: true,
                        title: 'Özellik Oluştur',
                    },
                },
                {
                    name: 'app.catalog.features.show',
                    path: ':id',
                    component: FeatureShow,
                    meta: {
                        auth: true,
                        title: 'Özellik Bilgileri',
                    },
                }
            ]
        },
    ]
}
