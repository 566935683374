<template>
    <div class="language-tab" v-if="language">
        <div class="inputs">
            <template v-for="text in items">
                <div class="fluid" :key="text.language_id" v-show="text.language_id == language.id">
                    <el-input v-if="type === 'string'" v-model="text[model]" :placeholder="text.language_title" />
                    <el-input v-if="type === 'text'" v-model="text[model]" :placeholder="text.language_title" type="textarea"  :rows="rows" />
                    <editor v-if="type === 'editor'" :container="text" :model="model" />
                </div>
            </template>
        </div>
        <div class="selector" v-if="elementSize(ui.languages) > 1">
            <el-dropdown @command="handleChange" trigger="click">
                <span class="el-dropdown-link">
                    <span :class="'fi fi-' + language.iso_code"></span>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <template v-for="(item, index) in ui.languages">
                        <el-dropdown-item :command="item" :key="index">
                            <span :class="'fi fi-' + item.iso_code"></span>
                        </el-dropdown-item>
                    </template>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'LanguageInput',
    props: {
        items : {
            //
        },
        model : {
            default : null
        },
        type : {
            default : 'string'
        },
        rows : {
            default : 3
        },
        placeholder : {
            default : null
        }
    },
    data() {
        return {
            language : null
        }
    },
    computed: mapGetters(['ui']),
    mounted() {
        this.language = this.cloneObjectData(this.ui.language);
    },
    methods: {
        handleChange(command) {
            this.language = command;
        }
    },
};
</script>
