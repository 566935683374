<template>
    <div :class="$route.meta.bodyClass">
        <header id="Header" v-if="$route.meta.layout !== 'content'">
            <div class="header">
                <el-container>
                    <div class="header-wrp">
                        <div class="items">
                            <el-popover placement="bottom-start" width="270" trigger="hover" popper-class="dropdown-menu dark account bottom" append-to-body>
                                <div class="item icon cursor-pointer" slot="reference">
                                    <icon source="office-building-cog-outline" />
                                </div>
                                <div class="body">
                                    <div class="head is-menu">
                                        <div class="media">
                                            <icon source="office-building-cog-outline" />
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ $t('menu.system') }}</div>
                                        </div>
                                    </div>
                                    <router-link to="/system/settings" class="link-item">
                                        <icon source="tune" class="media" />
                                        {{ $t('menu.settings') }}
                                    </router-link>
                                    <router-link to="/design/theme" class="link-item">
                                        <icon source="compare" class="media" />
                                        {{ $t('menu.themes') }}
                                    </router-link>
                                    <router-link to="/design/menus" class="link-item">
                                        <icon source="dots-horizontal-circle-outline" class="media" />
                                        {{ $t('menu.menus') }}
                                    </router-link>
                                    <router-link to="/system/users" class="link-item">
                                        <icon source="account-multiple" class="media" />
                                        {{ $t('menu.users') }}
                                    </router-link>
                                    <router-link to="/system/exports" class="link-item">
                                        <icon source="database-export-outline" class="media" />
                                        {{ $t('menu.excel_exports') }}
                                    </router-link>
                                </div>
                            </el-popover>

                            <el-popover placement="bottom-start" width="270" trigger="hover" popper-class="dropdown-menu dark account bottom" append-to-body>
                                <div class="item icon cursor-pointer">
                                    <icon source="briefcase-outline"/>
                                </div>
                                <div class="body">
                                    <div class="head is-menu">
                                        <div class="media">
                                            <icon source="briefcase-outline"/>
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ $t('menu.seo') }}</div>
                                        </div>
                                    </div>
                                    <router-link to="/cms/seo/links" class="link-item">
                                        <icon source="link-variant" class="media" />
                                        {{ $t('menu.seo_links') }}
                                    </router-link>
                                    <router-link to="/cms/seo/files" class="link-item">
                                        <icon source="file-document-multiple-outline" class="media" />
                                        {{ $t('menu.seo_files') }}
                                    </router-link>
                                    <router-link to="/cms/seo/redirects" class="link-item">
                                        <icon source="page-next-outline" class="media" />
                                        {{ $t('menu.seo_redirects') }}
                                    </router-link>
                                </div>
                            </el-popover>

                            <el-tooltip :content="$t('menu.forms')" placement="bottom">
                                <router-link to="/tools/form-messages" class="item icon">
                                    <icon source="android-messages" />
                                </router-link>
                            </el-tooltip>

                            <SearchHeader />
                        </div>
                        <div class="items">
                            <el-tooltip :content="$t('menu.file_manager')" placement="bottom">
                                <router-link to="/tools/file-manager" class="item icon">
                                    <icon source="folder-multiple-image" />
                                </router-link>
                            </el-tooltip>
                            <el-tooltip :content="$t('menu.plugins')" placement="bottom">
                                <router-link to="/plugins" class="item icon">
                                    <icon source="rocket-outline" />
                                </router-link>
                            </el-tooltip>
                            <ActivityHeader />
                        </div>
                    </div>
                </el-container>
            </div>
        </header>

        <main id="Main">
            <aside id="Sidebar">
                <div class="head">
                    <router-link to="/dashboard" class="logo">
                        <img src="/img/sidebar-logo.svg" alt="logo">
                    </router-link>
                </div>
                <MenuSidebar />
                <div class="footer">
                    <div class="account">
                        <Account placement="right-end" />
                    </div>
                </div>
            </aside>
            <el-container>
                <div class="main-wrp">
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <aside class="account-sidebar" v-if="account">
                                <div class="account-sidebar-wrp">
                                    <div class="head">{{ $t('account.menu.head_1') }}</div>
                                    <div class="items">
                                        <div>
                                            <router-link to="/account/me" class="link-item">
                                                <icon source="account-outline" /> {{ $t('account.menu.me') }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link to="/account/password" class="link-item">
                                                <icon source="lock-outline" /> {{ $t('account.menu.password') }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link to="/account/notifications" class="link-item">
                                                <icon source="bell-outline" /> {{ $t('account.menu.notifications') }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link to="/account/addresses" class="link-item">
                                                <icon source="map-marker-circle" /> {{ $t('account.menu.addresses') }}
                                            </router-link>
                                        </div>
                                    </div>
                                    <template v-if="account.role === 'admin'">
                                        <div class="head">{{ $t('account.menu.head_2') }}</div>
                                        <div class="items">
                                            <div>
                                                <router-link to="/account/companies/apps" class="link-item">
                                                    <icon source="apps" /> {{ $t('account.menu.apps') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/companies/settings" class="link-item">
                                                    <icon source="domain" /> {{ $t('account.menu.settings') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/companies/brands" class="link-item display-none">
                                                    <icon source="web" /> {{ $t('account.menu.brands') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/companies/mobile" class="link-item display-none">
                                                    <icon source="access-point" /> {{ $t('account.menu.mobile') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/companies/api" class="link-item display-none">
                                                    <icon source="api" /> {{ $t('account.menu.api') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/companies/ip" class="link-item display-none">
                                                    <icon source="shield-lock-outline" /> {{ $t('account.menu.ip') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/companies/workers" class="link-item">
                                                    <icon source="account-tie" /> {{ $t('account.menu.workers') }}
                                                </router-link>
                                            </div>
                                        </div>

                                        <div class="head">{{ $t('account.menu.head_3') }}</div>
                                        <div class="items">
                                            <div>
                                                <router-link to="/account/payments/subscriptions" class="link-item">
                                                    <icon source="credit-card-sync-outline" /> {{ $t('account.menu.subscription') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/payments/invoices" class="link-item">
                                                    <icon source="credit-card-search-outline" /> {{ $t('account.menu.history') }}
                                                </router-link>
                                            </div>
                                            <div>
                                                <router-link to="/account/payments/cards" class="link-item">
                                                    <icon source="credit-card-plus-outline" /> {{ $t('account.menu.cards') }}
                                                </router-link>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </aside>
                        </el-col>
                        <el-col :span="18">
                            <div class="account-breadcrumb">
                                <el-breadcrumb separator-class="el-icon-arrow-right">
                                    <el-breadcrumb-item>{{ $t('account.menu.head_1') }}</el-breadcrumb-item>
                                    <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
                                </el-breadcrumb>
                            </div>
                            <div class="account-section">
                                <router-view />
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-container>
        </main>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SearchHeader from "@/app/Layouts/Parts/Search";
import ActivityHeader from "@/app/Layouts/Parts/Activity";
import MenuSidebar from "@/app/Layouts/Parts/Menu";
import Account from "@/app/Layouts/Parts/Account";

export default {
    name: 'AccountLayout',
    components: {
        Account,
        MenuSidebar,
        ActivityHeader,
        SearchHeader
    },
    computed: mapGetters(['account']),
    mounted() {
        //
    }
}
</script>
