const TOKEN_KEY_NAME = 'auth_token';

export const getToken = () => {
    return window.localStorage.getItem(TOKEN_KEY_NAME);
};

export const storeToken = token => {
    window.localStorage.setItem(TOKEN_KEY_NAME, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(TOKEN_KEY_NAME);
};

export default {getToken, storeToken, destroyToken};
