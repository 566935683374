<template>
    <div class="box-tab">
        <div class="box">
            <div class="tabs">
                <div class="tab" v-for="(title, index) in titles" :key="index" :class="{active : index === active}" @click="handleClick(index)">
                    {{ title }}
                </div>
            </div>
        </div>
        <div class="tab-contents" :class="content_class">
            <slot name="contents"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabBox',
    props: ['titles', 'change', 'content_class'],
    data() {
        return {
            active: 0
        }
    },
    methods: {
        handleClick(value) {
            this.active = value;

            return this.change(value)
        }
    },
};
</script>
