<style lang="scss" rel="stylesheet/scss">
@import "./resources/scss/Bootstrap";
</style>

<template>
    <div id="Bootstrap">
        <transition name="fade">
            <div class="preloader-container" v-show="$store.state.preloading">
                <div class="container">
                    <div class="dot dot-1"></div>
                    <div class="dot dot-2"></div>
                    <div class="dot dot-3"></div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"/>
                        </filter>
                    </defs>
                </svg>
            </div>
        </transition>
        <router-view v-show="!$store.state.preloading" />
    </div>
</template>

<script>
import {GET_UI} from "@/store/actions.type";

export default {
    name: 'Bootstrap',
    mounted() {
        this.$store.dispatch(GET_UI);
    }
}
</script>
