<template>
    <div class="empty">
        <div class="media">
            <img :src="'/img/empty/' + alias + '.svg'" alt="empty">
        </div>
        <div class="text">
            <div class="title">{{ $t('empty.' + title) }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        alias: {
            default: 'empty'
        },
        title: {
            default: 'search'
        }
    },
};
</script>
