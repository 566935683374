<template>
    <div class="body">
        <div class="box-inline-headline first">Varsayılan Kategoriler</div>
        <el-row :gutter="24">
            <el-col :span="6">
                <el-form-item label="Blog">
                    <select-box remote="cms/categories?alias=posts" :container="form" valuetype="string" model="default_blog_category" placeholder="Blog"/>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Sıkça Sorulan Sorular">
                    <select-box remote="cms/categories?alias=faqs" :container="form" valuetype="string" model="default_faq_category" placeholder="Sıkça Sorulan Sorular"/>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Varsayılan Etkinlik Kategorisi">
                    <select-box remote="cms/categories?alias=events" :container="form" valuetype="string" model="default_event_category" placeholder="Varsayılan Etkinlik Kategorisi"/>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'CmsSetting',
    props : ['form'],
}
</script>
