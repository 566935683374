import PageLayout from "@/app/Layouts/PageLayout";
import ViewContainer from "@/app/Containers/ViewContainer";

// Settings
import Setting from "@/app/Pages/System/Settings/Index";
import ExcelExport from "@/app/Pages/System/ExcelExport";

// Users
import UserIndex from "@/app/Pages/System/Users/Index";
import UserCreate from "@/app/Pages/System/Users/Create";
import UserShow from "@/app/Pages/System/Users/Show";


export default {
    path: '/system',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Sistem',
    },
    children: [
        {
            name: 'app.system.settings.index',
            path: 'settings',
            component: Setting,
            meta: {
                auth: true,
                title: 'Ayarlar',
            },
        },
        {
            name: 'app.system.exports.index',
            path: 'exports',
            component: ExcelExport,
            meta: {
                auth: true,
                title: 'Excel Çıktıları',
            },
        },
        {
            path: 'users',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Kullanıcılar',
            },
            children : [
                {
                    name: 'app.system.users.index',
                    path: '',
                    component: UserIndex,
                    meta: {
                        auth: true,
                        title: 'Kullanıcılar',
                    },
                },
                {
                    name: 'app.system.users.create',
                    path: 'create',
                    component: UserCreate,
                    meta: {
                        auth: true,
                        title: 'Kullanıcı Oluştur',
                    },
                },
                {
                    name: 'app.system.users.show',
                    path: ':id',
                    component: UserShow,
                    meta: {
                        auth: true,
                        title: 'Kullanıcı Bilgileri',
                    },
                }
            ]
        },
    ]
}
