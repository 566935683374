import PageLayout from "@/app/Layouts/PageLayout";
import ViewContainer from "@/app/Containers/ViewContainer";

// Pages
import ThemeIndex from "@/app/Pages/Design/Theme/Index";

// Slider
import SliderIndex from "@/app/Pages/Design/Slider/Index";

// Menu
import MenuIndex from "@/app/Pages/Design/Menus/Index";
import MenuCreate from "@/app/Pages/Design/Menus/Create";
import MenuShow from "@/app/Pages/Design/Menus/Show";

export default {
    path: '/design',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Tasarım',
    },
    children: [
        {
            name: 'app.design.theme.index',
            path: 'theme',
            component: ThemeIndex,
            meta: {
                auth: true,
                title: 'Tema Bilgileri',
            },
        },
        {
            path: 'sliders',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Sliders',
            },
            children : [
                {
                    name: 'app.design.sliders.index',
                    path: '',
                    component: SliderIndex,
                    meta: {
                        auth: true,
                        title: 'Slider',
                    },
                },
            ]
        },
        {
            path: 'menus',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Menüler',
            },
            children : [
                {
                    name: 'app.design.menus.index',
                    path: '',
                    component: MenuIndex,
                    meta: {
                        auth: true,
                        title: 'Menüler',
                    },
                },
                {
                    name: 'app.design.menus.create',
                    path: 'create',
                    component: MenuCreate,
                    meta: {
                        auth: true,
                        title: 'Menü Oluştur',
                    },
                },
                {
                    name: 'app.design.menus.show',
                    path: ':id',
                    component: MenuShow,
                    meta: {
                        auth: true,
                        title: 'Menü',
                    },
                },
            ]
        },
    ]
}
