import Vue from 'vue'

// Components
import Icon from "./icon";
import Empty from "./empty";
import SelectBox from "./select-box";
import TabBox from "./tab-box";
import Breadcrumb from "./breadcrumb";
import Avatar from "./avatar";
import FormBox from "./form-box";
import VerticalTabBox from "./vertical-tab-box";
import Zones from "./zones";
import DefinitionBox from "./definition-box";
import StatusBox from "./status-box";
import CategoryBox from "./category-box";
import BrandBox from "./brand-box";
import AddressBox from "./address-box";
import PagePagination from "./page-pagination";
import Fs from "./fs";
import LanguageInput from "./language-input";
import Editor from "./editor";

Vue.component('icon', Icon);
Vue.component('empty', Empty);
Vue.component('select-box', SelectBox);
Vue.component('tab-box', TabBox);
Vue.component('breadcrumb', Breadcrumb);
Vue.component('avatar', Avatar);
Vue.component('form-box', FormBox);
Vue.component('vertical-tab-box', VerticalTabBox);
Vue.component('zones', Zones);
Vue.component('definition-box', DefinitionBox);
Vue.component('status-box', StatusBox);
Vue.component('category-box', CategoryBox);
Vue.component('brand-box', BrandBox);
Vue.component('address-box', AddressBox);
Vue.component('page-pagination', PagePagination);
Vue.component('fs', Fs);
Vue.component('language-input', LanguageInput);
Vue.component('editor', Editor);

