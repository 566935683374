import PageLayout from "@/app/Layouts/PageLayout";
import ViewContainer from "@/app/Containers/ViewContainer";

import FileManager from "@/app/Pages/Tools/FileManager";

import FormMessageIndex from "@/app/Pages/Tools/FormMessages/Index";
import FormMessageCreate from "@/app/Pages/Tools/FormMessages/Create";
import FormMessageShow from "@/app/Pages/Tools/FormMessages/Show";

export default {
    path: '/tools',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'Araçlar',
    },
    children: [
        {
            name: 'app.tools.file_manager.index',
            path: 'file-manager',
            component: FileManager,
            meta: {
                auth: true,
                title: 'Dosya Yöneticisi',
            },
        },
        {
            path: 'form-messages',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Form Mesajları',
            },
            children : [
                {
                    name: 'app.tools.form_messages.index',
                    path: '',
                    component: FormMessageIndex,
                    meta: {
                        auth: true,
                        title: 'Form Mesajları',
                    },
                },
                {
                    name: 'app.tools.form_messages.create',
                    path: 'create',
                    component: FormMessageCreate,
                    meta: {
                        auth: true,
                        title: 'Mesaj Oluştur',
                    },
                },
                {
                    name: 'app.tools.form_messages.show',
                    path: ':id',
                    component: FormMessageShow,
                    meta: {
                        auth: true,
                        title: 'Form Mesajı',
                    },
                }
            ]
        },
    ]
}
