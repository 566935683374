<template>
    <div>
        <div class="box-inline-headline first">Sliders</div>
        <div class="body list">
            <template v-for="(item, index) in form.file_data.sliders">
                <div class="li cursor-pointer" :key="index" @click="show(item)">
                    <div class="text">
                        <div class="title">{{ item.label }}</div>
                    </div>
                </div>
            </template>
        </div>
        <el-dialog title="Slider Bilgileri" :visible.sync="dialogVisible" width="70%" class="no-padding" :before-close="handleSave">
            <template v-if="slider">
                <el-form :label-position="'top'" ref="form" :model="form.meta[slider.value]">
                    <div class="box">
                        <div class="headline">
                            <div class="title">{{ slider.label }}</div>
                            <div class="actions">
                                <el-tooltip content="Ekle" placement="left">
                                    <el-button icon="el-icon-plus" type="primary" plain size="mini" @click="create" />
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="body no-padding">
                            <el-tabs v-model="activeTab" type="border-card" closable @tab-remove="destroy">
                                <template v-for="(form, index) in items">
                                    <el-tab-pane :label="'Slider - ' + index" :name="'key-' + index" :key="index">

                                        <el-form-item label="Slider Görünümü">
                                            <select-box :options="[{title : 'Resim', id : 'image'}, {title : 'Video', id : 'video'}]" :container="form" model="view_type" />
                                        </el-form-item>

                                        <el-row :gutter="24" v-if="form.view_type !== 'video'">
                                            <el-col :span="4">
                                                <el-form-item label="Resim">
                                                    <fs v-model="form.image_path" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-form-item label="Video" v-if="form.view_type === 'video'">
                                            <fs v-model="form.video_path" :isFile="true" />
                                        </el-form-item>

                                        <el-form-item label="İçerik" class="is-editor">
                                            <language-input type="editor" :items="form.texts" model="message" />
                                        </el-form-item>

                                        <el-form-item label="Siralama">
                                            <el-input v-model="form.sort" placeholder="Siralama" type="number" />
                                        </el-form-item>
                                    </el-tab-pane>
                                </template>
                            </el-tabs>
                        </div>
                    </div>
                </el-form>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props : ['form'],
    data() {
        return {
            dialogVisible : false,
            slider : null,
            items : [],
            activeTab : 0
        }
    },
    mounted() {
        //
    },
    methods: {
        show(slider) {
            this.activeTab = null;
            this.slider = slider;

            if (!this.form.meta[this.slider.value]) {
                this.form.meta[this.slider.value] = {
                    items : []
                }
            }

            if (!this.elementSize(this.form.meta[this.slider.value].items)) {
                this.form.meta[this.slider.value].items = [];
            }

            this.items = this.form.meta[this.slider.value].items;

            this.dialogVisible = true;
        },

        create() {

            let data = {
                view_type : 'image',
                image_path : null,
                video_path : null,
                texts : []
            }

            data.texts = this.initLanguageWithTextForm(data.texts)

            this.items.push(data)
        },

        destroy(targetName) {

            let key = targetName;

            if (!key) {
                key = this.activeTab;
            }

            key = key.replace('key-', '');

            this.$confirm(this.$t('notification.destroy'), this.$t('notification.warning_title'), this.confirmOption)
                .then(() => {
                    this.items.splice(key, 1);

                    return true;
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('notification.destroy_cancel')
                    });
                });
        },

        handleSave() {
            this.form.meta[this.slider.value].items = this.items;

            this.dialogVisible = false;
        }
    }
}
</script>
