<template>
    <div class="avatar" :class="{text : !source, image : source}" :style="{'background-color' : '#1e90ff'}">
        <template v-if="source">
            <img :src="publicFilePath(source)" :alt="findName()">
        </template>
        <template v-if="!source">
            {{ findName() }}
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Avatar',
        props: {
            name : {
                default : 'Ribrit'
            },
            source : {
                default : null
            }
        },
        methods : {
            findName() {

                if (typeof(this.name) === 'number') {
                    return this.name;
                }

                if (!this.name) {
                    return 'R';
                }

                return (this.name).slice(0, 1);
            },

            findBackgroundColor() {
                let colors = this.lodash.shuffle(this.predefineColors)

                return colors[0];
            }
        }
    };
</script>
