<template>
    <div class="item" @click="handleVisible()">
        <el-tooltip :content="$t('tools.activity.activity')" placement="bottom">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round">
                <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
            </svg>
        </el-tooltip>

        <el-drawer :title="$t('activity.title')" :visible.sync="visible" append-to-body class="activity" width="36%">
            <div class="activity-container" v-loading="pageLoading">
                <template v-for="(log, index) in items(storePath(path))">
                    <div class="item" :key="index">
                        <div class="action" :class="log.action">
                            <icon :source="log.action" />
                        </div>
                        <div class="text">
                            <div class="message" v-html="log.message"></div>
                            <div class="date">{{ log.created_at }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CRUD_ITEMS} from "@/store/actions.type";

export default {
    name: 'ActivityHeader',
    data() {
        return {
            visible: false,
            path: 'tools/logs',
            pageLoading: false,
            page: 1,
        }
    },
    computed: mapGetters(['pagination', 'items']),
    mounted() {

    },
    methods: {
        handleVisible() {
            this.visible = true;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEMS, {
                    path: this.path,
                    filter: this.filter,
                    page: this.page
                })
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
    }
}
</script>
